/* eslint-disable */
import React, { useState, useRef } from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { useInView } from 'react-intersection-observer';
import img5 from '../assets/images/features/feature30/img1.jpg';
import { HashLink as Link } from 'react-router-hash-link';
// import { Container, Row, Col } from 'reactstrap';
// import img1 from '../assets/images/portfolio/product1.jpg';
import img1 from '../assets/images/portfolio/grease1.jpg';
import img4 from '../assets/images/portfolio/grease2.jpg';
import img2 from '../assets/images/portfolio/product2.jpg';
import img3 from '../assets/images/portfolio/product3.jpg';
import CardFlip from 'react-card-flip';
import { Carousel } from 'react-bootstrap';


const ProductMain = () => {
    const [isFlipped1, setIsFlipped1] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isFlipped2, setIsFlipped2] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isFlipped3, setIsFlipped3] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);

    const handleMouseEnter1 = () => {
        if (!isFlipped1) {
            setIsHovered1(true);
            setIsFlipped1(true);
        } 
    };

    const handleMouseLeave1 = () => {
        if (isHovered1) {
            setIsHovered1(false);
            
        } else {
            setIsFlipped1(false);
        }
    };

    const handleMouseEnter2 = () => {
        if (!isFlipped2) {
            setIsHovered2(true);
            setIsFlipped2(true);
        } 
    };

    const handleMouseLeave2 = () => {
        if (isHovered2) {
            setIsHovered2(false);
            
        } else {
            setIsFlipped2(false);
        }
    };

    const handleMouseEnter3 = () => {
        if (!isFlipped3) {
            setIsHovered3(true);
            setIsFlipped3(true);
        } 
    };

    const handleMouseLeave3 = () => {
        if (isHovered3) {
            setIsHovered3(false);
            
        } else {
            setIsFlipped3(false);
        }
    };
    

    const [ref1, inView1] = useInView({
        triggerOnce: true, // 한 번만 트리거되도록 설정합니다.
        threshold: 0.5, // 요소가 화면의 50% 이상 보이는 경우를 감지합니다.
    });
    
    const [ref2, inView2] = useInView({
        triggerOnce: true, // 한 번만 트리거되도록 설정합니다.
        threshold: 0.5, // 요소가 화면의 50% 이상 보이는 경우를 감지합니다.
    });

    const [ref3, inView3] = useInView({
        triggerOnce: true, // 한 번만 트리거되도록 설정합니다.
        threshold: 0.5, // 요소가 화면의 50% 이상 보이는 경우를 감지합니다.
    });

    return (
        <div>
        <div className='m-t-50'></div>
        <Container>
            <Row><div className='company-bg-green' style={{ borderTopRightRadius : '10px', borderTopLeftRadius : '10px' }}><h1 className='custom-title text-white company-custom-font2' style={{ margin : '0 auto', marginTop : '20px' }}>What is Our Product?</h1></div></Row>
            <Row className='d-felx company-bg-green' style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
            <Col lg='12'>
            <div className='align-items-center'>
            <Carousel slide={false} data-bs-theme="dark" className='custom-banner-ca' controls={false} indicators={true} interval={3000}>
                <Carousel.Item className='carousel-item-cs'>
                    <img
                    className="d-block w-100"
                    src={img4}
                    alt="First slide"
                    />
                    <Carousel.Caption className='custom-carousel-caption1'>
                        <h3 className="custom company-custom-font2 card-font-ca">반도체용 그리스</h3>
                        <p style={{ textAlign: 'right' }}><Link to="/product3" className='nav-link m-t-20 company-custom-font3' style={{ fontSize: '0.78rem' }}>더보기 &gt;</Link></p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className='carousel-item-cs'>
                    <img
                    className="d-block w-100"
                    src={img1}
                    alt="Second slide"
                    />
                    <Carousel.Caption  className='custom-carousel-caption2'>
                        <h3 className="custom company-custom-font2 card-font-ca">디스플레이용 그리스</h3>
                        <p style={{ textAlign: 'right' }}><Link to="/product7" className='nav-link m-t-20 company-custom-font3' style={{ fontSize: '0.78rem' }}>더보기 &gt;</Link></p>
                        {/* <p>디스플레이용 그리스</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            {/* <div ref={ref1} className={`box ${inView1 ? 'visible' : ''}`}>
            <CardFlip isFlipped={isFlipped1} flipDirection="horizontal">
                <div className="card-hover" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <Card className={`card-shadow card-test first background1`} style={{ minHeight : '500px' }}>
                            <CardBody className="card-test">
                            <div className="p-20">
                                <h3 className="custom company-custom-font2 company-text-color company-text-font card-font">불소그리스</h3>
                                <p className='m-t-30 company-text-color'></p>
                            </div>
                            </CardBody>
                    </Card>
                </div>
                <div className="card-hover" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <Card className={`card-shadow card-test first`} style={{ minHeight : '500px' }}>
                        <img className="card-img-bottom" src={img1} alt="스마트에프앤에스 불소그리스" />
                        <CardBody className="card-test">
                        <div className="p-20">
                            <h3 className="company-custom-font3 text-white text-with-border" style={{ fontSize: '2.4rem' }}><br/><br/>불소그리스</h3>
                            <p><br/><br/></p>
                            <a className="btn btn-lg btn-rounded btn-info-gradiant btn-arrow" href="/product1"><span style={{ fontSize: '1.2rem' }}>더보기 <i className="ti-arrow-right"></i></span></a>
                        </div>
                        </CardBody>
                    </Card>
                </div>
            </CardFlip>
            </div>
            </div>
            </Col>
            <Col lg='6'>
            <div className="card-back">
            <CardBody className="card-test">
                <div className="p-20 card-content m-t-50">
                <div><h2 className='text-white custom-text-align company-custom-font3'><i className="fa fa-arrow-right company-text-color2" />&nbsp;제품 적용부위</h2></div>
                <p className='m-t-10 company-custom-font3' style={{ fontSize: '1.5rem' }}>내화학설비, 반도체 Process 장치, 우주선, 인공위성, 고진공설비, 핸드폰 힌지 등</p>
                </div>
            </CardBody> */ }
            </div>
            </Col>
            {/* <Col lg='6'>
            <div className="card-back">
            <CardBody className="card-test">
                <div className="p-20 card-content m-t-50">
                <div><h2 className='text-white custom-text-align company-custom-font3'><i className="fa fa-arrow-right company-text-color2" />&nbsp;제품 적용부위</h2></div>
                <p className='m-t-10 company-custom-font3' style={{ fontSize: '1.5rem' }}>Coolant(냉각용), 정밀세정제, 코팅제의 희석용, 발포첨가제</p>
                </div>
            </CardBody>
            </div>
            </Col> */}
            <Col lg='6'>
            <div className='align-items-center'>
            <div ref={ref2} className={`boxr ${inView2 ? 'visible' : ''}`}>
            <CardFlip isFlipped={isFlipped2} flipDirection="horizontal">
                <div className="card-hover" onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                    {/* 앞면 */}
                    <Card className={`card-shadow card-test first background2`} style={{ minHeight : '500px' }}>
                        {/* <img className="card-img-bottom" src={img1} alt="wrappixel kit" /> */}
                        <CardBody className="card-test">
                            <div className="p-20">
                                {/* <span className="label label-info label-rounded">No.1</span> */}
                                <h3 className="custom company-custom-font2 company-text-color company-text-font card-font">불소용제</h3>
                                <p className='m-t-30 company-text-color'></p>
                                <div className="p-20 card-content m-t-50">
                                <div><h2 className='company-text-color custom-text-align company-custom-font3'><i className="fa fa-arrow-right company-text-color2" />&nbsp;제품 적용부위</h2></div>
                                <p className='m-t-10 company-custom-font3 company-text-color' style={{ fontSize: '1.5rem', textAlign: 'left' }}>Coolant(냉각용), 정밀세정제, 코팅제의 희석용, 발포첨가제</p>
                                </div>
                                {/* <a className="btn btn-info-gradiant btn-sm btn-arrow m-t-20" href="#"><span>More Info <i className="ti-arrow-right"></i></span></a> */}
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="card-hover" onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                    {/* 뒷면 */}
                    <Card className={`card-shadow card-test first`} style={{ minHeight : '500px' }}>
                        <img className="card-img-bottom" src={img2} alt="스마트에프앤에스 불소용제" />
                        <CardBody className="card-test">
                        <div className="p-20">
                            {/* <span className="label label-info label-rounded">No.2</span> */}
                            <h3 className="company-custom-font3 text-white text-with-border" style={{ fontSize: '2.4rem' }}><br/><br/>불소용제</h3>
                            <p><br/><br/></p>
                            <a className="btn btn-lg btn-rounded btn-info-gradiant btn-arrow" href="/product6"><span style={{ fontSize: '1.2rem' }}>더보기 <i className="ti-arrow-right"></i></span></a>
                        </div>
                        </CardBody>
                    </Card>
                </div>
            </CardFlip>
            </div>
            </div>
            </Col>
            <Col lg='6'>
            <div className='align-items-center'>
            <div ref={ref3} className={`boxl ${inView3 ? 'visible' : ''}`}>
            <CardFlip isFlipped={isFlipped3} flipDirection="horizontal">
                <div className="card-hover" onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                    {/* 앞면 */}
                    <Card className={`card-shadow card-test first background3`} style={{ minHeight : '500px' }}>
                        {/* <img className="card-img-bottom" src={img1} alt="wrappixel kit" /> */}
                        <CardBody className="card-test">
                            <div className="p-20">
                                {/* <span className="label label-info label-rounded">No.1</span> */}
                                <h3 className="custom company-custom-font2 company-text-color company-text-font card-font">불소코팅제</h3>
                                <p className='m-t-30 company-text-color'></p>
                                <div className="p-20 card-content m-t-50">
                                <div><h2 className='company-text-color custom-text-align company-custom-font3'><i className="fa fa-arrow-right company-text-color2" />&nbsp;제품 적용부위</h2></div>
                                <p className='m-t-10 company-custom-font3 company-text-color' style={{ fontSize: '1.5rem', textAlign: 'left' }}>Oil barrier, Flux barrier, Repellent, Anti mositure, Conformal coating, Anti rust, Anti acid etc.</p>
                                </div>
                                {/* <a className="btn btn-info-gradiant btn-sm btn-arrow m-t-20" href="#"><span>More Info <i className="ti-arrow-right"></i></span></a> */}
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="card-hover" onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                    {/* 뒷면 */}
                    <Card className={`card-shadow card-test first`} style={{ minHeight : '500px' }}>
                        <img className="card-img-bottom" src={img3} alt="스마트에프앤에스 불소코팅제" />
                        <CardBody className="card-test">
                        <div className="p-20">
                            {/* <span className="label label-info label-rounded">No.3</span> */}
                            <h3 className="company-custom-font3 text-white text-with-border" style={{ fontSize: '2.4rem' }}><br/><br/>불소코팅제</h3>
                            <p><br/><br/></p>
                            <a className="btn btn-lg btn-rounded btn-info-gradiant btn-arrow" href="/product8"><span style={{ fontSize: '1.2rem' }}>더보기 <i className="ti-arrow-right"></i></span></a>
                        </div>
                        </CardBody>
                    </Card>
                </div>
            </CardFlip>
            </div>
            </div>
            </Col>
            {/* <Col lg='6'>
            <div className="card-back">
            <CardBody className="card-test">
                <div className="p-20 card-content m-t-50">
                <div><h2 className='text-white custom-text-align company-custom-font3'><i className="fa fa-arrow-right company-text-color2" />&nbsp;제품 적용부위</h2></div>
                <p className='m-t-10 company-custom-font3' style={{ fontSize: '1.5rem' }}>Oil barrier, Flux barrier, Repellent, Anti mositure, Conformal coating, Anti rust, Anti acid etc.</p>
                </div>
            </CardBody>
            </div>
            </Col> */}
            </Row>
            </Container>
        </div>
        
    );
};

export default ProductMain;