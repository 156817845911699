import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, NavbarBrand, Navbar, Nav, NavbarToggler, Collapse, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Alert } from 'reactstrap';
import logo from '../assets/images/logos/logo_white2.png';
import axios from 'axios';
import ProductList from './ProductList';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [username, setUsername] = useState(''); // 추가
  const [userid, setUserid] = useState(''); // 추가
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [error, setError] = useState('');
  
  // 세션에서 사용자 정보 확인
  useEffect(() => {
    // 세션에서 사용자 정보 확인
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
    }
  }, []);


  const toggle = () => setIsOpen(!isOpen);
  const toggleLoginModal = () => setLoginModal(!loginModal);
  const toggleSignupModal = () => setSignupModal(!signupModal);

  const handleLogin = async () => {
    const useridLogin = document.getElementById('useridLogin').value;
    const passwordLogin = document.getElementById('passwordLogin').value;

    try {
        const response = await axios.post('/adminLogin', {
            userid: useridLogin,
            password: passwordLogin
        });

        if (response.status === 200) {
            const data = response.data;
            console.log(response.data);
            console.log('로그인 성공! 사용자 ID:', data.userid);
            console.log('로그인 성공! 사용자 이름:', data.username);
            setUsername(data.username); // 로그인 성공 시 사용자 이름 설정
            setLoginError(null); // 로그인 오류 초기화
            toggleLoginModal(false); // 로그인 모달 닫기
            setSignupSuccess(true); // 로그인 성공 시 Alert 창 표시

            // 세션에 사용자 정보 저장
            sessionStorage.setItem('user', JSON.stringify({ username: data.username, userid: data.userid }));
            window.location.reload();

        } else {
            throw new Error('로그인 오류');
        }
    } catch (error) {
        console.error('로그인 오류:', error);
        setLoginError('로그인 중 오류가 발생했습니다.');
    }
};

  const handleSignup = async () => {
    const formData = new FormData();
    formData.append('userid', document.getElementById('userid').value);
    formData.append('username', document.getElementById('username').value);
    formData.append('userpassword', document.getElementById('userpassword').value);
    formData.append('contact', document.getElementById('contact').value);
    formData.append('email', document.getElementById('email').value);
    formData.append('verificationCode', document.getElementById('verificationCode').value);
    
    try {
        const response = await axios.post('/adminSignup', formData, {
            headers: {
                'Content-Type': 'application/json', // 파일 업로드 시에는 multipart/form-data로 설정
            }
        });

        if (response.status === 200) {
            console.log('회원가입 성공:', response.data.message);
            // 회원가입 성공 시 추가적인 동작 수행 가능
            toggleSignupModal(false);
            // 회원가입 성공을 알리는 alert 창 띄우기
            // window.alert('회원가입이 성공적으로 완료되었습니다.');
            setSignupSuccess(true);

        } else {
            console.error('회원가입 오류:', response.data.error);
            // 회원가입 오류 메시지를 화면에 표시하거나 적절한 동작 수행
            setError(response.data.error); // 오류 메시지를 상태에 저장
        }
    } catch (error) {
        console.error('회원가입 중 오류 발생:', error);
        window.alert('관리자 회원가입용 인증코드가 올바르지 않습니다.');
        // 회원가입 중 오류 발생 시 적절한 처리 수행
    }
};

  const checkPasswordMatch = () => { // 추가
    const password = document.getElementById('userpassword').value;
    const confirmPassword = document.getElementById('confirmPassword').value;
    setPasswordMatch(password === confirmPassword);
  };

  const handleLogout = () => {
    // 세션에서 사용자 정보 제거
    sessionStorage.removeItem('user');
    setUsername(''); // 사용자 이름 초기화
    setUserid('');
    window.location.reload(); // 페이지 새로고침
  };

  return (
    <div>
      <div className="header1 fixed-top bg-test">
        <Container className="po-relative">
          <Navbar className="navbar-expand-lg h1-nav">
            <NavbarBrand href="/"><img src={logo} alt="wrapkit" /></NavbarBrand>
            <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
            <Collapse isOpen={isOpen} navbar id="header1">
              <Nav navbar className="ms-auto mt-2 mt-lg-0 company-custom-font2" style={{ textAlign : 'right' }}> 
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className='text-white' style={{ fontSize : '22px'}}>
                    회사소개 <i className="fa fa-angle-down m-l-5"></i>
                  </DropdownToggle>
                  <DropdownMenu className="b-none animated fadeInUp" style={{ fontSize : '17px'}}>
                    <DropdownItem><NavLink to="/company1" className='nav-link'>인사말</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/company2" className='nav-link'>연혁</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/company3" className='nav-link'>조직도</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/company4" className='nav-link'>오시는길</NavLink></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className='text-white' style={{ fontSize : '22px'}}>
                    제품소개 <i className="fa fa-angle-down m-l-5"></i>
                  </DropdownToggle>
                  <DropdownMenu className="b-none animated fadeInUp">
                    <ProductList />
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className='text-white' style={{ fontSize : '22px'}}>
                    연구개발 장비 <i className="fa fa-angle-down m-l-5"></i>
                  </DropdownToggle>
                  <DropdownMenu className="b-none animated fadeInUp" style={{ fontSize : '17px'}}>
                    <DropdownItem><NavLink to="/machine1" className='nav-link'>연구개발 장비</NavLink></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className='text-white' style={{ fontSize : '22px'}}>
                    도움말 <i className="fa fa-angle-down m-l-5"></i>
                  </DropdownToggle>
                  <DropdownMenu className="b-none animated fadeInUp" style={{ fontSize : '17px'}}>
                    <DropdownItem><NavLink to="/support1" className='nav-link'>공지사항</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/support2" className='nav-link'>상담문의</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/support3" className='nav-link'>자료실</NavLink></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Collapse/>
              </Nav>
              <Nav className="m-l-20" navbar>
                {/* 로그인 버튼 또는 사용자 이름 표시 */}
                {username ? (
                  <div className="d-flex align-items-center">
                    <p className="text-white m-2 font-bold">{username} 님</p>
                    <Button outline color="danger" className="btn-sm m-2" onClick={handleLogout}>로그아웃</Button>
                  </div>
                ) : (
                  <Button outline color="success" className="m-2" onClick={toggleLoginModal}>로그인</Button>
                )}
                <Modal isOpen={loginModal} toggle={toggleLoginModal} className='company-custom-font2'>
                  <ModalHeader toggle={toggleLoginModal}><h3 className='modal-title text-black company-custom-font2 m-t-10'>SMART F&S 관리자 로그인</h3></ModalHeader>
                  <Alert color="success" isOpen={signupSuccess} toggle={() => setSignupSuccess(false)}>
                    회원가입이 성공적으로 완료되었습니다.
                  </Alert>
                  <ModalBody>
                    <FormGroup>
                      <Label for="useridLogin">아이디</Label>
                      <Input type="text" name="useridLogin" id="useridLogin" placeholder="아이디를 입력하세요" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="passwordLogin">비밀번호</Label>
                      <Input type="password" name="passwordLogin" id="passwordLogin" placeholder="비밀번호를 입력하세요" />
                    </FormGroup>
                    {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handleLogin}>로그인</Button>{' '}
                    <Button color="secondary" onClick={toggleLoginModal}>취소</Button>
                  </ModalFooter>
                  <Button color="info" onClick={toggleSignupModal}>회원가입</Button>
                  {/* 로그인 성공 시 사용자 이름 또는 아이디 표시 */}
                  {username && <p>안녕하세요, {username}님</p>}
                </Modal>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </div>
      <Modal isOpen={signupModal} toggle={toggleSignupModal}>
      {/* 회원가입 모달 내용 */}
      <ModalHeader toggle={toggleSignupModal}>회원가입</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="userid">아이디</Label>
          <Input type="text" name="userid" id="userid" placeholder="아이디를 입력하세요" />
        </FormGroup>
        <FormGroup>
          <Label for="username">사용자 이름</Label>
          <Input type="text" name="username" id="username" placeholder="사용자 이름을 입력하세요" />
        </FormGroup>
        <FormGroup>
          <Label for="userpassword">비밀번호</Label>
          <Input type="password" name="userpassword" id="userpassword" placeholder="비밀번호를 입력하세요" onChange={checkPasswordMatch} />
        </FormGroup>
        <FormGroup>
          <Label for="confirmPassword">비밀번호 확인</Label>
          <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="비밀번호를 다시 입력하세요" onChange={checkPasswordMatch} />
          {!passwordMatch && <p style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</p>} {/* 추가 */}
        </FormGroup>
        <FormGroup>
          <Label for="contact">연락처</Label>
          <Input type="text" name="contact" id="contact" placeholder="연락처를 입력하세요" />
        </FormGroup>
        <FormGroup>
          <Label for="email">이메일</Label>
          <Input type="email" name="email" id="email" placeholder="이메일을 입력하세요" />
        </FormGroup>
        <FormGroup>
          <Label for="verificationCode">인증코드</Label>
          <Input type="text" name="verificationCode" id="verificationCode" placeholder="인증코드를 입력하세요" />
          {error && <p style={{ color: 'red' }}>{error}</p>} {/* 오류 메시지 표시 */}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSignup}>가입하기</Button>{' '}
        <Button color="secondary" onClick={toggleSignupModal}>취소</Button>
      </ModalFooter>
    </Modal>
    </div>
  );
}

export default Header;
