import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../Header';
import Footer from '../Footer';
import machine from '../../assets/images/banner/equipment.jpg';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { GrVirtualMachine } from "react-icons/gr";
import machine1 from '../../assets/images/machine/machine_1.jpg';
import machine2 from '../../assets/images/machine/machine_2.jpg';
import machine3 from '../../assets/images/machine/machine_3.jpg';
import machine4 from '../../assets/images/machine/machine_4.png';
import machine5 from '../../assets/images/machine/machine_5.png';
import machine6 from '../../assets/images/machine/machine_6.png';
import machine7 from '../../assets/images/machine/machine_7.png';
import machine8 from '../../assets/images/machine/machine_8.png';
import machine9 from '../../assets/images/machine/machine_9.png';

const Machine1 = () => {
    const images = [
        { src: machine1, text: "이유도 시험기" },
        { src: machine2, text: "저온 토크 측정기" },
        { src: machine3, text: "적외선 분광기" },
        { src: machine4, text: "산화 안정도기" },
        { src: machine5, text: "4구 극압 시험기" },
        { src: machine6, text: "4구 마모 시험기" },
        { src: machine7, text: "혼화 안정도기" },
        { src: machine8, text: "주도기 침입도계" },
        { src: machine9, text: "적점 시험기" }
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0); // 모달에서 보여줄 이미지의 인덱스
  
    const openModal = (index) => {
      setModalImageIndex(index); // 클릭된 이미지의 인덱스 설정
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const showNextImage = () => {
      const nextIndex = (modalImageIndex + 1) % images.length; // 다음 이미지의 인덱스 계산
      setModalImageIndex(nextIndex); // 다음 이미지의 인덱스 설정
    };
  
    const showPreviousImage = () => {
      const previousIndex = (modalImageIndex - 1 + images.length) % images.length; // 이전 이미지의 인덱스 계산
      setModalImageIndex(previousIndex); // 이전 이미지의 인덱스 설정
    };

  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper m-t-106 company-bg">
        <div className="modal-open">
          <Row className="justify-content-center">
            <Col className="align-items-center b-b-custom">
              <div className="image-container">
                <img src={machine} alt="smartfns" />
                <div className="background-layer"></div>
                <div className="image-text m-t-20" style={{ verticalAlign: 'middle' }}>
                  <h1 className="text-white company-custom-font3" style={{ fontSize: '4rem' }}> R&D Equipment</h1>
                  <p className="company-custom-font3 m-t-15">주요 장비</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="spacer" />
        <div className="m-t-50"></div>
        <Container className="company-custom-font">
          <h1 className="title company-text-color company1 company-custom-font2 header-font">
            <GrVirtualMachine className="company-text-color2 m-b-10" />&nbsp;주요 장비
          </h1>
          <div className="company-boder-top1 m-t-30 m-b-5"></div>
          <div className="company-boder-top2"></div>
          <Row style={{ marginTop : '15px' }}>
            {images.map((image, index) => (
              <Col xs={4} className="mb-4" key={index}>
              <div className="image-container-ma" style={{ border: '1px solid white' }} onClick={() => openModal(index)}>
                <img src={image.src} alt={`Image ${index + 1}`} />
                <div className="image-overlay">
                  <p className="company-custom-font6 text-black machine-font">{image.text}</p>
                </div>
              </div>
            </Col>
            ))}
          </Row>
          <div className="company-boder-top2 m-b-5"></div>
          <div className="company-boder-top1"></div>
        </Container>
        <div className="spacer"></div>
        <Footer />
      </div>
      {isModalOpen && (
        <div className="modal-overlay-cs" onClick={closeModal}>
          <div className="modal-content-cs" onClick={(e) => e.stopPropagation()}>
            <div className="modal-image-container-cs">
              <button className="modal-btn-left" onClick={showPreviousImage}>
                <FaChevronLeft />
              </button>
              <img src={images[modalImageIndex].src} alt={`Modal Image ${modalImageIndex + 1}`} />
              <button className="modal-btn-right" onClick={showNextImage}>
                <FaChevronRight />
              </button>
            </div>
            <button className="modal-btn-close btn" onClick={closeModal}>닫기</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Machine1;