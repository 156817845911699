
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { RiBuilding2Line } from "react-icons/ri";
import { FaGears } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { FaBoxes } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import Header  from '../Header';
import Footer  from '../Footer';
import { FcTimeline } from "react-icons/fc";
import building2 from '../../assets/images/banner/building2.jpg'
import ScrollToTop from '../Scroll';

const Company2 = () => {
    return (
        <div id="main-wrapper">
            <ScrollToTop />
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={building2} alt="smartfns"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3 main-font'>About SMART F&S</h1>
                                <p className='company-custom-font3 m-t-15'>Introduction</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='spacer'></div>
                <Container className='justifiy-content-center'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><FcTimeline className='text-info m-b-20' />&nbsp;연혁</h1>
                        <div className='company-boder-top1 m-t-30 m-b-5'></div>
                        <div className='company-boder-top2'>
                        <Row className='timeline m-t-30'> 
                            <Col className='timeline-body'>
                                <VerticalTimeline>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2010"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<RiBuilding2Line/>}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">법인설립</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - SMART F&S 법인설립
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2011"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaGears />}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">윤활제 개발</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 액상윤활제 개발<br />
                                    - 중국사무소 개설
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2012"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<IoMdCheckmarkCircleOutline />}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">승인 및 납품</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - NSF승인 <br />
                                    - 중국 그리수 수출 <br />
                                    - 삼성전자 핸드폰사업부 납품
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2013"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaBoxes />}

                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">제품 판매</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - Miteni사 Fluro Solvent 판매<br />
                                    - 레노버 노트북 힌지 그리스 납품
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2014"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<TbTruckDelivery />}

                                >   
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">개발 및 납품</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 삼성전자 TV사업부 불소윤활제 개발 <br />
                                    - 에어컨용 그리스 개발
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2015"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaBoxes />}

                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">판매 및 납품</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - Fluro Solvent 판매<br />
                                    - 자동차 불소 그리스 납품
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2016"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaUserGear />}

                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">개발 및 납품</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 불소코팅제 개발완료<br />
                                    - 중국 암페놀 불소그리스 납품
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2017"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaBoxes />}

                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">판매</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - HFE판매개시<br />
                                    - 중국HP 불소그리스 납품
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2018"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaUserGear />}

                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">반도체용 Grease 개발 및 납품</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 반도체, 디스플레이 Grease 개발
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2019"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaBoxes />}

                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">희석제 판매</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 지문방지용-희석제 판매
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2020"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaGears />}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">진공 그리스 개발</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 진공Pump용 그리스 개발
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2021"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaGears />}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">폴더블폰 그리스 개발</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 폴더블폰 그리스 개발 2종류-중국
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2022"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaGears />}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">애플노트북 그리스 개발</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 애플노트북 그리스 개발
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work text-black"
                                    date="2023"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FaGears />}
                                >
                                    <h3 className="vertical-timeline-element-title text-black company-custom-font2">불소 이형 코팅제 개발</h3>
                                    <h4 className="vertical-timeline-element-subtitle text-black"></h4>
                                    <p className='text-black company-custom-font3'>
                                    - 불소 이형 코팅제 개발완료
                                    </p>
                                </VerticalTimelineElement>
                                </VerticalTimeline>
                            </Col> 
                        </Row>
                        {/* <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div> */}
                        <div className='spacer'></div>
                        </div>
                </Container>
                <Footer/>
            </div>
        </div>
    )
}


export default Company2;