import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Table, Row, Col, Button, Badge } from 'reactstrap'; // 예시로 사용된 테이블 라이브러리
import { GrAnnounce } from "react-icons/gr";
import { BsDatabaseFillDown } from "react-icons/bs"
import { Link } from 'react-router-dom';
import support from '../assets/images/banner/support1.jpg'

const MainBoard = ({ isLoggedIn }) => { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');

  useEffect(() => {
    fetchData();
    checkSession(); // 세션 정보 확인
  }, []); 


  const fetchData = async () => {
    try {
      const response = await axios.get(`/selectMainBoards`);
      setData(response.data);
      
      // noticeBoard 데이터 처리
      const noticeBoardData = response.data.noticeBoard;

      // dataBoard 데이터 처리
      const dataBoardData = response.data.dataBoard;

      setLoading(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  return (
        <>
        <div className='spacer' />
        {/* <Container>
        <div className="consultation-banner">
          <div className="banner-content">
            <img src={support} alt="스마트에프앤에스 상담문의" className='banner-img'/>
            <h1 className='company-custom-font2 text-white' style={{ fontSize: '2.5rem' }}>주요장비</h1>
            <p className='company-custom-font3'>언제든지 견적 및 비용, 자료요청 관련 문의 부탁드립니다.</p>
            <Link to="/qnaPost"><Button outline color="primary" className="banner-link company-custom-font3">더보기</Button></Link>
          </div>
        </div>
        </Container>
        <div className='spacer' /> */}
        <Container>
          <Row className="justify-content-center">
                <Col md="6" className="m-b-40">
                    <h1 className="company-text-color2 company-custom-font2" style={{ textAlign : 'center'}}>More Information</h1>
                    {/* <h6 className="subtitle">You can relay on our amazing features list and also our customer services will be great experience for you without doubt and in no-time</h6> */}
                </Col>
            </Row>
        </Container>
        <Container className='justifiy-content-center' style={{ border: '2px solid black', borderRadius: '20px', padding: '2%' }}>
          {/* {userid && ( // userid가 null이 아닌 경우에만 버튼 표시
            <div className='buttons m-b-10'>
              <Button outline color="info" className='btn btn-sm font-bold'><Link to='/noticePost'>글쓰기</Link></Button>{' '}
            </div>
          )} */}
          <Row>
            <Col lg='6' md='6' style={{ borderRight: '1px solid lightgray'}}>
            <div  style={{ display: 'flex', alignItems: 'center' }}>
            <h2 className='text-black m-t-5 custom-text-align m-l-10 company-custom-font2'><GrAnnounce className="fa fa m-b-5 company-text-color2"/>&nbsp;공지사항</h2>
            <p style={{ textAlign: 'right' }}><Link to="/support1" className='nav-link text-black m-t-20 company-custom-font3' style={{ fontSize: '0.78rem' }}>더보기 &gt;</Link></p>
            </div>
            <Table style={{ tableLayout: 'fixed' }}>
              <tbody style={{ textAlign: 'center' }} className='company-custom-font5'>
                {loading ? (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  ) : (
                    data.noticeBoard.map((row, idx) => ( // 여기서 data를 사용
                      <tr key={idx} className='text-black'>
                        {/* <td>{row.idx}</td> */}
                        <td style={{ textAlign: 'left', width: '65%' }}><Link to={`/noticeReadView/${row.idx}`} className='nav-link'>{row.title}</Link></td>
                        {/* <td style={{ textAlign: 'right' }}><Badge color="success" className='font-bold'>관리자</Badge></td> */}
                        <td style={{ textAlign: 'right', width: '35%' }}>{row.regdate}</td>
                      </tr>
                    ))
                  )}
              </tbody>
            </Table>
            </Col>
            <Col lg='6' md='6' style={{ borderLeft: '1px solid lightgray'}}>
            <div  style={{ display: 'flex', alignItems: 'center' }}>
            <h2 className='text-black m-t-5 custom-text-align m-l-10 company-custom-font2'><BsDatabaseFillDown className="fa fa m-b-5 company-text-color2"/>&nbsp;자료실</h2>
            <p style={{ textAlign: 'right' }}><Link to="/support3" className='nav-link text-black m-t-20 company-custom-font3' style={{ fontSize: '0.78rem' }}>더보기 &gt;</Link></p>
            </div>
            <Table style={{ float: 'left', tableLayout: 'fixed' }}>
              <tbody style={{ textAlign: 'center' }} className='company-custom-font5'>
              {loading ? (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  ) : (
                    data.dataBoard.map((row, idx) => ( // 여기서 data를 사용
                      <tr key={idx} className='text-black'>
                        <td style={{ textAlign: 'left', width: '65%' }}><Link to={`/dataReadView/${row.idx_data}`} className='nav-link'>{row.title_data}</Link></td>
                        <td style={{ textAlign: 'right', width: '35%' }}>{row.regdate_data}</td>
                      </tr>
                    ))
                  )}
              </tbody>
            </Table>
            </Col>
          </Row>
        </Container>
        <div className='spacer' />
        <Container>
        <div className="consultation-banner">
          <div className="banner-content">
            <img src={support} alt="스마트에프앤에스 상담문의" className='banner-img'/>
            <h1 className='company-custom-font2 text-white' style={{ fontSize: '2.5rem' }}>견적 및 상담문의</h1>
            <p className='company-custom-font3'>언제든지 견적 및 비용, 자료요청 관련 문의 부탁드립니다.</p>
            <Link to="/qnaPost"><Button outline color="primary" className="banner-link company-custom-font3">문의하기</Button></Link>
          </div>
        </div>
        </Container>
        <div className='spacer'></div>
      </>
  );
}

export default MainBoard;