
import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import product1 from '../../assets/images/banner/banner2.jpg'
import { FaGears } from "react-icons/fa6";
import Pchoice1 from './Pchoice1';

const Product1 = () => {
    const products = [
        {
            productCode: "FGM-404",
            color: "White",
            nlgi: "2",
            temperature: "-60~120",
            characteristic: ["내화학설비", "반도체 Process 장치", "우주선", "인공위성", "고진공설비", "핸드폰 힌지", "골게이터", "프린터/복사기의 Heating rolloer bearing"
                            , "도장라인의 트롤리 bearing", "섬유텐더 bearing"
                            ]
        },
        {
            productCode: "FGM-406",
            color: "White",
            nlgi: "2",
            temperature: "-45~150"
        },
        {
            productCode: "FGM-408",
            color: "White",
            nlgi: "2",
            temperature: "-40~180"
        },
        {
            productCode: "FGM-410",
            color: "White",
            nlgi: "3",
            temperature: "-35~200"
        },
        {
            productCode: "FGM-450",
            color: "White",
            nlgi: "3",
            temperature: "-30~200"
        },
        {
            productCode: "FGM-432",
            color: "White",
            nlgi: "2",
            temperature: "-20~220"
        },
        {
            productCode: "FGM-450",
            color: "White",
            nlgi: "2",
            temperature: "-60~250"
        },
        {
            productCode: "FGM-530",
            color: "White",
            nlgi: "2",
            temperature: "-60~250"
        },
        {
            productCode: "FGM-535",
            color: "White",
            nlgi: "2",
            temperature: "-55~250"
        }
        // 나머지 제품들...
    ];

    return (
        <div id="main-wrapper">
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={product1} alt="smartfns 그리스"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Product</h1>
                                <p className='company-custom-font3 m-t-15'>Grease</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Pchoice1 />
            <div className='m-t-50'></div>
                <Container className='company-custom-font'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><FaGears className='company-text-color2 m-b-10' />&nbsp;불소그리스</h1>
                        <div className='company-boder-top1 m-t-30 m-b-5'></div>
                        <div className='company-boder-top2'></div>
                        <Row className='background-layer1' style={{ margin: '0 auto', marginTop: '15px', marginBottom: '15px', overflowX: 'auto', fontSize: '1.2rem'}}>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Product</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>외관<br />Color</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>주도<br />NLGI</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>사용온도범위(℃)</th>
                                            <th style={{ textAlign: 'center', width: '40%' }}>특성 / 적용부위<br />(Characteristic / Application)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => (
                                            <tr key={index} style={{ lineHeight :'2rem' }}>
                                                <td style={{ textAlign: 'center' }}>{product.productCode}</td>
                                                <td style={{ textAlign: 'center' }}>{product.color}</td>
                                                <td style={{ textAlign: 'center' }}>{product.nlgi}</td>
                                                <td style={{ textAlign: 'center', borderRight : '1px solid #ddd' }}>{product.temperature}</td>
                                                {index === 0 && (
                                                    <td rowSpan={products.length} style={{ verticalAlign: 'middle', paddingLeft: '2%' }}>
                                                        <div>
                                                            {product.characteristic.map((item, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    {item}
                                                                    {idx !== product.characteristic.length - 1 && <br />}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div>
                </Container>
                <div className='spacer'></div>
                <Footer/>
            </div>
        </div>
    )
}

export default Product1;