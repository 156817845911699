import React from 'react';
import { Pagination, PaginationItem, PaginationLink, Container, Row, Col } from 'reactstrap';

const PagePagination = ({ currentPage, itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink first onClick={() => paginate(1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
            </PaginationItem>
            {pageNumbers.map(number => (
              <PaginationItem key={number} active={number === currentPage}>
                <PaginationLink onClick={() => paginate(number)}>
                  {number}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink next onClick={() => paginate(currentPage + 1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink last onClick={() => paginate(totalPages)} />
            </PaginationItem>
          </Pagination>
  );
}

export default PagePagination;