import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Table, Row, Col, Button, Badge } from 'reactstrap'; // 예시로 사용된 테이블 라이브러리
import { RiQuestionAnswerFill } from "react-icons/ri";
import { BiReply } from "react-icons/bi";
import { FaLock } from "react-icons/fa6";
import PagePagination from './PagePagination'; // PagePagination 컴포넌트 import
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import support from '../../assets/images/banner/support1.jpg'

const QnaMain = ({ isLoggedIn }) => { 
  const [data, setData] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [replyData, setReplyData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');

  useEffect(() => {
    fetchData();
    checkSession(); // 세션 정보 확인
  }, [currentPage]); 

  const fetchData = async () => {
    try {
        const response = await axios.get('/selectQna');  
        setData(response.data);

        const response1 = await axios.get('/selectAdmin');        
        setAdmin(response1.data);

        const replyResponse = await axios.get('/selectReply')
        setReplyData(replyResponse.data);

        setLoading(false);
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
};

  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="main-wrapper">
        <Header isLoggedIn={isLoggedIn} username={username} />
        <div className="page-wrapper m-t-106 company-bg">
        <div className='modal-open'> 
            <Row className='justify-content-center'>
                <Col className='align-items-center b-b-custom'>
                    <div className="image-container">
                        <img src={support} alt="smartfns"/>
                        <div className="background-layer"></div>
                        <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Support</h1>
                                <p className='company-custom-font3 m-t-30'>Q&A</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='spacer'></div>
        <Container className='justifiy-content-center'>
          <div className='m-b-20'>
            <h1 className='text-black m-t-5 custom-text-align m-l-10 company-custom-font2 header-font'><RiQuestionAnswerFill className='fa fa m-b-5 company-text-color2'/>&nbsp;상담문의</h1>
            {/* <div className='b-b'><i className="fa fa-3x fa-bookmark m-b-5 d-flex text-info"><h1 className='text-black m-t-5 custom-text-align m-l-10'>&nbsp;공지사항</h1></i></div> */}
          </div>
          <div className='buttons m-b-10'>
            <Button outline color="info" className='btn btn-sm font-bold'><Link to='/qnaPost'>글쓰기</Link></Button>{' '}
          </div>
          <div className='table-responsive'>
          <Table>
              <thead style={{ textAlign: 'center' }}>
                <tr className='company-custom-font4'>
                  <th style={{ width: '10%' }}>No</th>
                  <th style={{ width: '65%', textAlign: 'left' }}>Title</th>
                  <th style={{ width: '10%', textAlign: 'right' }}>Role</th>
                  <th style={{ width: '15%', textAlign: 'left'}}>Author</th>
                  {/* <th style={{ width: '20%', textAlign: 'left' }}>RegDate</th> */}
                </tr>
              </thead>
              <tbody style={{ textAlign: 'center' }} className='company-custom-font5'>
                {loading ? (
                  <tr>
                    <td colSpan="6">Loading...</td>
                  </tr>
                ) : (
                  currentItems.map((row, idx) => (
                    <React.Fragment key={idx}>
                      <tr className='text-black'>
                        <td>{row.idx_qna}</td>
                        {row.secret_qna === 't' ? (
                        <td style={{ textAlign: 'left' }}>
                          <Link to={`/qnaReadView/${row.idx_qna}`} className='nav-link'><FaLock/>&nbsp; {row.title_qna}</Link>
                        </td>
                        ) : (
                          <td style={{ textAlign: 'left' }}>
                            <Link to={`/qnaReadView/${row.idx_qna}`} className='nav-link'>{row.title_qna}</Link>
                          </td>
                        )}
                        <td style={{ textAlign: 'right' }}>
                        {(() => {
                            let isAdmin = false;
                            for (let i = 0; i < admin.length; i++) {
                                if (admin[i].user_id === row.writer_qna) {
                                    isAdmin = true;
                                    break;
                                }
                            }
                            return (
                                <Badge key={0} color={isAdmin ? "success" : "warning"} className='font-bold'>
                                    {isAdmin ? "ADMIN" : "GUEST"}
                                </Badge>
                            );
                        })()}
                        </td>
                        <td style={{ textAlign: 'left' }}>{row.writer_qna}</td>
                        {/* <td style={{ textAlign: 'left' }}>{row[4]}</td> */}
                      </tr>
                      {replyData[row.idx_qna] && (
                        <tr className='text-black'>
                          <td colSpan="5" style={{ textAlign : 'left' }}>
                            <Link to={`/qnaReadView/${row.idx_qna}`} style={{ marginLeft : '10%' }} className='nav-link'><BiReply className='fa-rotate-180'/> [RE] : {row.title_qna} </Link>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </Table>
            <div className='parent-container'>
            <PagePagination currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={data.length} paginate={paginate} />
            </div>
          </div>
        </Container>
        <div className='spacer'></div>
        <Footer />
      </div>
    </div>
  );
}

export default QnaMain;