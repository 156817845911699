import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import Footer from '../Footer';
import Header from '../Header';
import support from '../../assets/images/banner/support1.jpg'

const NoticeUpdate = () => {
  const navigate = useNavigate();
  const { idx } = useParams();
  const [post, setPost] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState([]);
  
  // Fetch notice data when component mounts
  useEffect(() => {
    const fetchQna = async () => {
      try {
        const response = await axios.get(`/qnaRead/${idx}`);
        const dataObject = response.data;
  
        // 객체 데이터를 배열로 변환
        const dataArray = Object.values(dataObject).filter(data => data !== null);
  
        // 공지사항 데이터 설정
        const qnaData = dataArray[0];
        setTitle(qnaData.title_qna);
        setContent(qnaData.content_qna);
  
        // 파일 데이터가 있는 경우에만 처리
        const fileData = dataArray.map(data => ({
          name: data.filename,
          size: data.filesize,
          // 필요한 파일 속성들 추가
        })).filter(file => file.name && file.size); // 파일 이름과 사이즈가 있는 경우만 필터링
  
        setFiles(fileData);
  
      } catch (error) {
        console.error('공지사항 정보를 불러오는 중 오류가 발생했습니다:', error);
      }
        // try {
        //   const response = await axios.get(`/qnaRead/${idx}`);
        //   const dataArray = Object.values(response.data);
        //   setPost(dataArray);
        //   // const notice = response.data; // 첫 번째 객체를 가져옴

        //   const title = dataArray[0][1]; // 객체에서 title 속성에 접근
        //   const content = dataArray[0][3]; // 객체에서 content 속성에 접근
        //   const fileOrg = dataArray[0][7];
          
        //   setTitle(dataArray[0][1]);
        //   setContent(dataArray[0][3]);


        // } catch (error) {
        //   console.error('공지사항 정보를 불러오는 중 오류가 발생했습니다:', error);
        // }
      };

    fetchQna();
  }, [idx]);

  const onDrop = (acceptedFiles) => {
    // 새로운 파일 목록 생성
    const newFiles = [...files, ...acceptedFiles];
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('idx', idx);
      formData.append('title', title);
      formData.append('content', content);
  
      if (files.length > 0) {
        // 파일이 있을 때만 FormData에 파일을 추가합니다.
        files.forEach((file, index) => {
          formData.append(`file_${file.name}_${index}`, file);
        });
      } else {
        // 파일이 없을 때는 application/json으로 요청을 보내도록 설정합니다.
        const jsonData = {
          idx: idx,
          title: title,
          content: content
        };
        formData.append('data', JSON.stringify(jsonData));
      }
      
      const config = {
        headers: {
          'Content-Type': files.length > 0 ? 'multipart/form-data' : 'application/json',
        },
      };
  
      const url = files.length > 0 ? `/qnaUpdateFile/${idx}` : `/qnaUpdate/${idx}`;
      const response = await axios.post(url, formData, config);
      
      if (response.data.success) {
        console.log('게시글이 성공적으로 수정되었습니다:', response.data.message);
        navigate('/support2'); // 업로드 완료 후 페이지 이동 또는 다른 동작 수행
      } else {
        console.error('게시글 수정 중 오류가 발생했습니다:', response.data.message);
        // 업로드 실패 시 사용자에게 알림을 제공하거나 다른 처리를 수행
      }
    } catch (error) {
      console.error('게시글을 수정하는 중 오류가 발생했습니다:', error);
    }
    // try {
    //   const formData = new FormData();
    //   formData.append('idx', idx); // 수정할 공지사항의 idx 전달
    //   formData.append('title', title);
    //   formData.append('content', content);
    //   // 파일이 존재하는 경우에만 FormData에 파일 추가
    //   if (files.length > 0) {
    //     files.forEach((file, index) => {
    //       const encodedFilename = encodeURIComponent(file.name);
    //       formData.append(`file${index}`, file, encodedFilename); // 파일 객체를 FormData에 고유한 이름으로 추가
    //     });
    
    //     // FormData 객체의 키-값 쌍을 로깅
    //     for (const entry of formData.entries()) {
    //       console.log(entry);
    //     }  
        
    //   // 파일이 존재하는 경우에는 '/noticePost' 엔드포인트로 요청을 보냄
    //   var response = await axios.post('/qnaUpdateFile/${idx}', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data', // 파일 업로드를 위한 헤더 설정
    //     },
    //   });

    //   } else {
    //     // 파일이 없는 경우에는 '/noticePostNoFile' 엔드포인트로 요청을 보냄
    //     var response = await axios.post(`/qnaUpdate/${idx}`, formData, {
    //       headers: {
    //         'Content-Type': 'application/json', // 또는 'application/x-www-form-urlencoded'
    //       }
    //     });
    //   }

    //   console.log('게시글이 성공적으로 수정되었습니다:', response.data);
      
    //   // 성공 시 페이지 전환
    //   navigate('/support2');
    // } catch (error) {
    //   console.error('게시글을 수정하는 중 오류가 발생했습니다:', error);
    //   // 오류 처리
    // }
  };

  const handleRemoveFile = async (fileName) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    try {
        await axios.delete(`/qnaDeleteFile/${idx}/${encodeURIComponent(fileName)}`); // 파일 이름을 인코딩하여 전달

        // 파일을 삭제한 후 화면에서도 삭제
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    } catch (error) {
        console.error('파일 삭제 중 오류가 발생했습니다:', error);
    }
  };

  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper m-t-106 company-bg">
        <div className='modal-open'> 
            <Row className='justify-content-center'>
                <Col className='align-items-center b-b-custom'>
                    <div className="image-container">
                        <img src={support} alt="스마트에프앤에스"/>
                        <div className="background-layer"></div>
                        <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Support</h1>
                                <p className='company-custom-font3 m-t-30'>QnA</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='spacer'></div>
        <Container className='justifiy-content-center'>
          <h1 className='text-black company-custom-font3'>상담문의 수정 페이지</h1>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="title">제목</Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="제목을 입력하세요"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="content">내용</Label>
              <Input style={{ minHeight: '500px' }}
                type="textarea"
                name="content"
                id="content"
                placeholder="내용을 입력하세요"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <div {...getRootProps()} style={dropzoneStyle}>
                <input {...getInputProps()} type="file" name='file' />
                {files.length > 0 ? (
                  <div style={{ textAlign : 'left' }}>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                      {files.map((file) => (
                        <li key={file.name} style={{ marginBottom: '10px' }}>
                          {file.name} - {file.size} bytes
                          <Button color='danger' className='btn btn-sm'
                            onClick={(e) => {
                              e.stopPropagation(); // 클릭 이벤트 전파 막기
                              handleRemoveFile(file.name);
                            }}
                            style={{ marginLeft: '10px' }}
                          >
                            삭제
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="text-black">
                    클릭하거나 이미 드래그하여 업로드하세요.
                  </p>
                )}
              </div>
            </FormGroup>
            <Button type="submit" color="primary">
              게시글 수정
            </Button>
          </Form>
        </Container>
        <div className='spacer'></div>
        <Footer />
      </div>
    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default NoticeUpdate;
