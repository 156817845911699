
import PropTypes from "prop-types";
import React, { useState } from 'react';
// import Home from './pages/Home';
// import HeaderComponent from './views/custom-components/sections/headercomponent'
import Banner from './pages/Banner'
import ProductMain from './pages/ProductMain'
import reportWebVitals from './reportWebVitals';
import Direction from './pages/Direction';
import Footer from './pages/Footer';
import Header from './pages/Header';
import MainBoard from "./pages/MainBoard";
import ScrollToTop from "./pages/Scroll";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setUsername(username);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
  };

    return (
      <div id="main-wrapper">
      <ScrollToTop />
      <Header isLoggedIn={isLoggedIn} username={username} onLogin={handleLogin} onLogout={handleLogout} />
      <div className="page-wrapper m-t-106">
        <div className="container-fluid modal-open">
          <Banner />
          <ProductMain />
          {/* <Direction /> */}
          <MainBoard />
          <Footer />
        </div>
      </div>
    </div>
  );
}


App.propTypes = {
  classes: PropTypes.object
};

export default App;
reportWebVitals();

