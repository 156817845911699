
import React from 'react';
import OrganizationChart from 'nextjs-orgchart'  //OrganizationChart가 아닌 다른 명칭으로 써서 불러와도 노상관
import '../../assets/scss/chartcontainer.css' // 조직도 외부 껍데기 CSS
import '../../assets/scss/chartnode.css'      // 조직도 내부 CSS

const data = {
    id: "n1",
    name: "대표이사",
    title: "CEO",
    children: [
    {
        id: "n2",
        name: "감사",
        title: "Audit",
        children: [
        { 
            id: "n3",
            name: "관리팀",
            title: "Management",
            children: [
            { id: "n7", name: "총무", title: "manager" },
            { id: "n8", name: "회계", title: "accounting" }
            ]
        },
        {
            id: "n4",
            name: "영업팀",
            title: "Marketing",
            children: [
            { id: "n9", name: "국내 영업", title: "domestic" },
            { id: "n10", name: "해외 영업", title: "overseas" }
            ]
        },
        {
            id: "n5",
            name: "생산팀",
            title: "Manufacture",
            children: [
            { id: "n11", name: "생산1", title: "team No.1" },
            { id: "n12", name: "생산2", title: "team No.2" }
            ]
        },
        {
            id: "n6",
            name: "품질팀",
            title: "Quality",
            children: [
            { id: "n13", name: "Q-C", title: "team No.1" },
            { id: "n14", name: "Q-A", title: "team No.2" }
            ]
        }
        ]
    }
    ]
  }

const OrgChart = () => {
    return (
    <div>
    <OrganizationChart datasource={data} pan={false} zoom={false}/>
    </div>
    )
}


export default OrgChart;
