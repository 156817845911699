import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import Footer from '../Footer';
import Header from '../Header';
import Banner from '../Banner';
import support from '../../assets/images/banner/support1.jpg'

const DataPost = ({ isLoggedIn }) => { 
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');

  useEffect(() => {
    checkSession(); // 세션 정보 확인
  }, [isLoggedIn]); 
  
  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  console.log('username:', username);
  console.log('userid:', userid);

  const onDrop = (acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      formData.append('userid', userid);
      

      // 파일이 존재하는 경우에만 FormData에 파일 추가
      if (files.length > 0) {
        files.forEach((file, index) => {
          const encodedFilename = encodeURIComponent(file.name);
          formData.append(`file${index}`, file, encodedFilename); // 파일 객체를 FormData에 고유한 이름으로 추가
        });
    
        // FormData 객체의 키-값 쌍을 로깅
        for (const entry of formData.entries()) {
          console.log(entry);
        }  
        
      // 파일이 존재하는 경우에는 '/noticePost' 엔드포인트로 요청을 보냄
      var response = await axios.post('/dataPostFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 파일 업로드를 위한 헤더 설정
        },
      });

      } else {
        // 파일이 없는 경우에는 '/noticePostNoFile' 엔드포인트로 요청을 보냄
        var response = await axios.post('/dataPostFile', formData, {
          headers: {
            'Content-Type': 'application/json', // 또는 'application/x-www-form-urlencoded'
          }
        });
      }


    console.log('게시글이 성공적으로 등록되었습니다:', response.data);
    // 성공적으로 게시글이 등록되면 다음 작업을 수행할 수 있습니다.

    navigate('/support3');
  
    } catch (error) {
      console.error('게시글을 등록하는 중 오류가 발생했습니다:', error);
      // 오류 발생 시 사용자에게 알림을 표시하거나 오류 처리를 수행할 수 있습니다.
      window.alert('게시글 등록 중 오류가 발생하였습니다.');
    }
  };

  const handleBackClick = () => {
    navigate(`/support3`);
  };

  const handleRemoveFile = (name) => {
    setFiles(files.filter((file) => file.name !== name));
  };

  return (
    <div id="main-wrapper">
        <Header isLoggedIn={isLoggedIn} username={username} />
        <div className="page-wrapper m-t-106 company-bg">
        <div className='modal-open'> 
            <Row className='justify-content-center'>
                <Col className='align-items-center b-b-custom'>
                    <div className="image-container">
                        <img src={support} alt="스마트에프앤에스"/>
                        <div className="background-layer"></div>
                        <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Support</h1>
                                <p className='company-custom-font3 m-t-30'>Data</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='spacer'></div>
        <Container className='justifiy-content-center'>
        <h1 className='text-black company-custom-font3'>자료실 작성 게시판</h1>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="title">제목</Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="제목을 입력하세요"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="content">내용</Label>
            <Input style={{ minHeight : '500px' }}
              type="textarea"
              name="content"
              id="content"
              placeholder="내용을 입력하세요"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <div {...getRootProps()} style={dropzoneStyle}>
              <input {...getInputProps()} type="file" name='file' onChange={handleFileChange} />
              {files.length === 0 ? (
                <p className="text-black">
                  클릭하거나 이미지를 드래그하여 업로드하세요.
                </p>
              ) : (
                <div style={{ textAlign : 'left' }}>
                  <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {files.map((file) => (
                      <li key={file.name} style={{ marginBottom: '10px' }}>
                        {file.name} - {file.size} bytes
                        <Button color='secondary' className='btn btn-sm'
                          onClick={(e) => { e.stopPropagation(); handleRemoveFile(file.name); }}
                          style={{ marginLeft: '10px' }}
                        >
                          삭제
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </FormGroup>
          <div className='text-center'>
          <Button type="submit" color="primary" className='m-r-10'>
            등록
          </Button>
          <Button color="primary" className='btn' onClick={handleBackClick}>돌아가기</Button>
          </div>
        </Form>
        </Container>
        <div className='spacer'></div>
        <Footer />
      </div>
    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default DataPost;
