import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, FormGroup, Label, Input, Button, Row, Col, Alert } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import Footer from '../Footer';
import Header from '../Header';
import support from '../../assets/images/banner/support1.jpg';

const QnaPost = ({ isLoggedIn }) => { 
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState([]);
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');
  const [writer, setWriter] = useState('');
  const [password, setPassword] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [isSecret, setIsSecret] = useState(false); // 비밀글 체크 여부 상태 추가
  const navigate = useNavigate();
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    checkSession(); // 세션 정보 확인
  }, [isLoggedIn]); 
  
  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  const onDrop = (acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if (!userid) {
        // 작성자, 비밀번호, 연락처 중 하나라도 비어있으면 alert 메시지 표시
        if (!writer || !password || !contact) {
          setAlertVisible(true);
          return;
        }
      }

      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      formData.append('writer', userid || writer);
      formData.append('password', password);
      formData.append('contact', contact);
      formData.append('email', email);
      const secretValue = isSecret ? 't' : 'f';
      formData.append('secretValue', secretValue); // 비밀글 여부를 폼 데이터에 추가

      // 파일이 존재하는 경우에만 FormData에 파일 추가
      if (files.length > 0) {
        files.forEach((file, index) => {
          const encodedFilename = encodeURIComponent(file.name);
          formData.append(`file${index}`, file, encodedFilename); // 파일 객체를 FormData에 고유한 이름으로 추가
        });
    
        // FormData 객체의 키-값 쌍을 로깅
        // for (const entry of formData.entries()) {
        //   console.log(entry);
        // }  
        
        // 파일이 존재하는 경우에는 '/noticePost' 엔드포인트로 요청을 보냄
        var response = await axios.post('/qnaPostFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // 파일 업로드를 위한 헤더 설정
          },
        });
      } else {
        // 파일이 없는 경우에는 '/noticePostNoFile' 엔드포인트로 요청을 보냄
        var response = await axios.post('/qnaPostFile', formData, {
          headers: {
            'Content-Type': 'application/json', // 또는 'application/x-www-form-urlencoded'
          }
        });
      }

      console.log('게시글이 성공적으로 등록되었습니다:', response.data);
      // 성공적으로 게시글이 등록되면 다음 작업을 수행할 수 있습니다.

      navigate('/support2');
  
    } catch (error) {
      console.error('게시글을 등록하는 중 오류가 발생했습니다:', error);
      // 오류 발생 시 사용자에게 알림을 표시하거나 오류 처리를 수행할 수 있습니다.
      window.alert('게시글 등록 중 오류가 발생하였습니다.');
    }
  };

  const handleBackClick = () => {
    navigate(`/support2`);
  };

  const handleRemoveFile = (name) => {
    setFiles(files.filter((file) => file.name !== name));
  };

  const handleCheckboxChange = () => {
    setIsSecret(!isSecret); // 상태를 반전시켜서 체크 여부를 변경합니다.
    // 서버로 전달할 값을 't' 또는 'f'로 변환합니다.
    const secretValue = isSecret ? 'f' : 't';
    console.log('비밀글 값:', secretValue); // 테스트를 위해 콘솔에 출력합니다.
    // 이제 서버로 secretValue를 전달하면 됩니다.
  };

  const onDismiss = () => {
    setAlertVisible(false);
  };

  return (
    <div id="main-wrapper">
        <Header isLoggedIn={isLoggedIn} username={username} />
        <div className="page-wrapper m-t-106 company-bg">
        <div className='modal-open'> 
            <Row className='justify-content-center'>
                <Col className='align-items-center b-b-custom'>
                    <div className="image-container">
                        <img src={support} alt="스마트에프앤에스"/>
                        <div className="background-layer"></div>
                        <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Support</h1>
                                <p className='company-custom-font3 m-t-30'>Q&A</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='spacer'></div>
        <Container className='justifiy-content-center'>
        <h1 className='text-black company-custom-font3'>상담문의 작성 게시판</h1>
        <Form onSubmit={handleSubmit} style={{ marginTop : '80px' }}>
        {!userid && ( // userid가 존재하는 경우에만 렌더링
          <>
            <Row>
              <Col md={6}>
                  <FormGroup>
                      <Label for="writer">작성자</Label>
                      <Input
                          type="text"
                          name="writer"
                          id="writer"
                          placeholder="작성자를 입력하세요"
                          value={writer}
                          onChange={(e) => setWriter(e.target.value)}
                      />
                  </FormGroup>
              </Col>
              <Col md={6}>
                  <FormGroup>
                      <Label for="password">비밀번호</Label>
                      <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="비밀번호를 입력하세요"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                      />
                  </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                  <FormGroup>
                      <Label for="contact">연락처</Label>
                      <Input
                          type="text"
                          name="contact"
                          id="contact"
                          placeholder="연락처를 입력하세요"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                      />
                  </FormGroup>
              </Col>
              <Col md={6}>
                  <FormGroup>
                      <Label for="email">이메일</Label>
                      <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="이메일을 입력하세요"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                      />
                  </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <FormGroup style={{ marginTop : '80px' }}>
            <Label for="title">제목</Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="제목을 입력하세요"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Row>
                <Col md={6}>
                <Label for="content">내용</Label>
                </Col>
                <Col md={6} style={{ textAlign: 'right' }}>
                <FormGroup check>
                    <Label check>
                    <Input type="checkbox" checked={isSecret} onChange={handleCheckboxChange} />{' '}
                    비밀글
                    </Label>
                </FormGroup>
                </Col>
            </Row>
            <Input
                style={{ minHeight: '500px' }}
                type="textarea"
                name="content"
                id="content"
                placeholder="내용을 입력하세요"
                value={content}
                onChange={(e) => setContent(e.target.value)}
            />
            </FormGroup>
          <FormGroup>
            <div {...getRootProps()} style={dropzoneStyle}>
              <input {...getInputProps()} type="file" name='file' onChange={handleFileChange} />
              {files.length === 0 ? (
                <p className="text-black">
                  클릭하거나 이미지를 드래그하여 업로드하세요.
                </p>
              ) : (
                <div style={{ textAlign : 'left' }}>
                  <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {files.map((file) => (
                      <li key={file.name} style={{ marginBottom: '10px' }}>
                        {file.name} - {file.size} bytes
                        <Button color='secondary' className='btn btn-sm'
                          onClick={(e) => { e.stopPropagation(); handleRemoveFile(file.name); }}
                          style={{ marginLeft: '10px' }}
                        >
                          삭제
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </FormGroup>
          <div className='text-center'>
          <Alert color="danger" isOpen={alertVisible} toggle={onDismiss}>
          작성자, 비밀번호, 연락처는 필수 입력 사항입니다.
         </Alert>  
          <Button type="submit" color="primary" className='m-r-10'>
            등록
          </Button>
          <Button color="primary" className='btn' onClick={handleBackClick}>돌아가기</Button>
          </div>
        </Form>
        </Container>
        <div className='spacer'></div>
        <Footer />
      </div>
    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default QnaPost;
