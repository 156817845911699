/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <div className="footer4 b-t spacer">
            <Container>
                <Row>
                    <Col lg="4" md="6" className="m-b-30">
                        <p className='text-white text-center'>
                        <span className='d-flex font-bold'>KOREA OFFICE</span>
                        <span className='d-flex'>&nbsp;</span>
                        <span className='d-flex'>75-47, Jangdae-gil, llsandong-gu,</span>
                        <span className='d-flex'>Goyang-si, Gyeonggi-do</span>
                        <span className='d-flex'>&nbsp;</span>
                        <span className='d-flex'>TEL : +82 (0)70-8158-6414</span>
                        <span className='d-flex'>FAX : +82-31-901-8238</span>
                        </p>
                    </Col>
                    <Col lg="4" md="6" className="m-b-30">
                        <p className='text-white text-center'>
                        <span className='d-flex font-bold'>CHINA OFFICE</span>
                        <span className='d-flex'>&nbsp;</span>
                        <span className='d-flex'>302, E-block, Niushan, Dongchengqu,</span>
                        <span className='d-flex'>Dongguan City, Guangdong Province</span>
                        <span className='d-flex'>&nbsp;</span>
                        <span className='d-flex'>TEL : +86 769-2286858</span>
                        <span className='d-flex'>FAX : +86-769-22826958</span>
                        </p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <p className='text-white text-center'>
                        <span className='d-flex font-bold'>EMAIL</span>
                        <span className='d-flex'>&nbsp;</span>
                        <span className='d-flex'>Office :  smartfns@naver.com</span>
                        </p>
                    </Col>
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="12">
                            <div className="d-flex font-14 justify-content-between">
                                <div className="m-t-10 m-b-10 copyright">All Rights Reserved by SMART F&S Co., LTD.</div>
                                <div className="links ms-auto m-t-10 m-b-10">
                                    <a href='#' className="p-10 p-l-0 text-white">Terms of Use</a>
                                    <a href='#' className="p-10 text-white">Legal Disclaimer</a>
                                    <a href='#' className="p-10 text-white">Privacy Policy</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
export default Footer;
