import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Table, Row, Col, Button, Badge } from 'reactstrap'; // 예시로 사용된 테이블 라이브러리
import { GrAnnounce } from "react-icons/gr";
import PagePagination from './PagePagination'; // PagePagination 컴포넌트 import
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import support from '../../assets/images/banner/support1.jpg'

const NoticeMain = ({ isLoggedIn }) => { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');

  useEffect(() => {
    fetchData();
    checkSession(); // 세션 정보 확인
  }, [currentPage]); 


  const fetchData = async () => {
    try {
      const response = await axios.get(`/selectNotice`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="main-wrapper">
        <Header isLoggedIn={isLoggedIn} username={username} />
        <div className="page-wrapper m-t-106 company-bg">
        <div className='modal-open'> 
            <Row className='justify-content-center'>
                <Col className='align-items-center b-b-custom'>
                    <div className="image-container">
                        <img src={support} alt="smartfns"/>
                        <div className="background-layer"></div>
                        <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Support</h1>
                                <p className='company-custom-font3 m-t-30'>Notice</p>
                        </div>
                    </div>
                </Col>
            </Row>
         </div>
        <div className='spacer'></div>
        <Container className='justifiy-content-center'>
          <div className='m-b-20'>
          <h1 className='text-black m-t-5 custom-text-align m-l-10 company-custom-font2 header-font'><GrAnnounce className="fa fa m-b-5 company-text-color2"/>&nbsp;공지사항</h1>
            {/* <div className='b-b'><i className="fa fa-3x fa-bookmark m-b-5 d-flex text-info"><h1 className='text-black m-t-5 custom-text-align m-l-10'>&nbsp;공지사항</h1></i></div> */}
          </div>
          {userid && ( // userid가 null이 아닌 경우에만 버튼 표시
            <div className='buttons m-b-10'>
              <Button outline color="info" className='btn btn-sm font-bold'><Link to='/noticePost'>글쓰기</Link></Button>{' '}
            </div>
          )}
          <div className='table-responsive'>
            <Table>
              <thead style={{ textAlign: 'center' }}>
                <tr className='company-custom-font4'>
                  <th style={{ width: '10%' }}>No</th>
                  <th style={{ width: '65%', textAlign: 'left' }}>Title</th>
                  <th style={{ width: '10%', textAlign: 'right' }}>Role</th>
                  <th style={{ width: '15%', textAlign: 'left'}}>Author</th>
                  {/* <th style={{ width: '20%', textAlign: 'left' }}>RegDate</th> */}
                </tr>
              </thead>
              <tbody style={{ textAlign: 'center' }} className='company-custom-font5'>
                {loading ? (
                  <tr>
                    <td>Loading...</td>
                  </tr>
                ) : (
                  currentItems.map((row, idx) => (
                    <tr key={idx} className='text-black'>
                      <td >{row.idx}</td>
                      <td style={{ textAlign: 'left'}}><Link to={`/noticeReadView/${row.idx}`} className='nav-link'>{row.title}</Link></td>
                      <td style={{ textAlign: 'right' }}><Badge color="success" className='font-bold'>관리자</Badge></td>
                      <td style={{ textAlign: 'left' }}>{row.writer}</td>
                      {/* <td style={{ textAlign: 'left', fontSize: '10px' }}>{row[4]}</td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className='parent-container'>
            <PagePagination currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={data.length} paginate={paginate} />
            </div>
          </div>
        </Container>
        <div className='spacer'></div>
        <Footer />
      </div>
    </div>
  );
}

export default NoticeMain;