
// import React, { useState } from 'react';
// import {
//     Carousel,
//     CarouselItem,
//     CarouselControl,
//     CarouselIndicators,
//     CarouselCaption,
//     Row,
//     Col
// } from 'reactstrap';

// import img1 from '../assets/images/banner/banner4.jpg';
// import img2 from '../assets/images/banner/banner2.jpg';
// import img3 from '../assets/images/banner/banner3.jpg';

// const items = [
//     {
//         src: img1,
//         altText: '',
//         caption: 'SMART F&S'
//     },
//     {
//         src: img2,
//         altText: '',
//         caption: ''
//     },
//     {
//         src: img3,
//         altText: '',
//         caption: ''
//     }
// ];

// const Banner = (props) => {

//     const [activeIndex, setActiveIndex] = useState(0);
//     const [animating, setAnimating] = useState(false);

//     const next = () => {
//         if (animating) return;
//         const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
//         setActiveIndex(nextIndex);
//     }

//     const previous = () => {
//         if (animating) return;
//         const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
//         setActiveIndex(nextIndex);
//     }

//     const goToIndex = (newIndex) => {
//         if (animating) return;
//         setActiveIndex(newIndex);
//     }
    
//     const slides = items.map((item, index) => (
//         <CarouselItem
//           className="custom-tag"
//           onExiting={() => setAnimating(true)}
//           onExited={() => setAnimating(false)}
//           key={item.src}
//         >
//           <div className="carousel-content">
//             <img className="mw-100" src={item.src} alt={item.altText} />
//             {item.caption && (
//               <div className="carousel-caption">
//                 {item.caption.split('').map((char, charIndex) => (
//                   <span
//                     key={charIndex}
//                     className="animated-char gradient-text"
//                     style={{ animationDelay: `${index * 5 + charIndex * 0.6}s` }}
                    
//                   >
//                     {char === ' ' ? '\u00A0' : char} {/* 띄어쓰기를 유니코드 non-breaking space로 대체 */}
//                   </span>
//                 ))}
//               </div>
//             )}
//           </div>
//         </CarouselItem>
//       ));

//     return (
//         <div className='modal-open'> 
//                 <Row className='justify-content-center'>
//                     <Col className='align-items-center b-b-custom'>
//                         <Carousel 
//                             activeIndex={activeIndex}
//                             next={next.bind(null)}
//                             previous={previous.bind(null)}
//                             interval={8000}
//                         >
//                             <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex.bind(null)} />
//                             {slides}
//                             <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous.bind(null)} />
//                             <CarouselControl direction="next" directionText="Next" onClickHandler={next.bind(null)} />
//                         </Carousel>
//                     </Col>
//                 </Row>
//         </div>
//     );
// }

// export default Banner;

import Carousel from 'react-bootstrap/Carousel';
import img1 from '../assets/images/banner/banner_2.jpg';
// import img1 from '../assets/images/banner/banner4.jpg';
import img2 from '../assets/images/banner/banner_1.jpg';
import img3 from '../assets/images/banner/banner_6.jpg';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

const Banner = (props) => {
  const [showCaption, setShowCaption] = useState(false);
  
  useEffect(() => {
    setShowCaption(true);
  }, []);

  const text = 'SMART F&S';
  
  return (
    <div className='modal-open'> 
      <Row className='justify-content-center'>
        <Col className='align-items-center b-b-custom'>
        <Carousel fade interval={6000} className='carousel-content'>
          <Carousel.Item>
            <img src={img1} alt="SMART F&S" />
            <Carousel.Caption style={showCaption ? null : { display: 'none' }}>
              <div className="carousel-caption">
                {text.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className="animated-char gradient-text"
                    style={{ animationDelay: `${charIndex * 0.6}s` }}
                  >
                    {char === ' ' ? '\u00A0' : char}
                  </span>
                ))}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={img2} alt="스마트 에프앤에스" />
            {/* <Carousel.Caption>
              <p>(주) 스마트에프앤에스 </p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img src={img3} alt="반도체 및 디스플레이용 그리스" />
          </Carousel.Item>
        </Carousel>
          {/* <Row className='justify-content-center'>
            <Col className='align-items-center b-b-custom'>*/}
              
            </Col>
          </Row>
      </div>
  );
}

export default Banner;