
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import product1 from '../../assets/images/banner/banner2.jpg';
import sf1 from '../../assets/images/product/SF-FG4-TDS_1.jpg';
import sf2 from '../../assets/images/product/SF-FG4-TDS_2.jpg';
import { FaGears } from "react-icons/fa6";
import Pchoice1 from './Pchoice1';

const Product4 = () => {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const products = [
        {
            productCode: "SF-VG 5",
            color: "White",
            nlgi: "2",
            temperature: "-70~250",
            eva: "-",
            vp: "4.6×10E-12",
            thick: "PTFE",
            bo: "PFPE"
        },
        {
            productCode: "SF-VG 3",
            color: "White",
            nlgi: "2",
            temperature: "-65~250",
            eva: "-",
            vp: "1.4×10E-11",
            thick: "PTFE",
            bo: "PFPE"
        },
        {
            productCode: "SF-VG31",
            color: "White",
            nlgi: "2",
            temperature: "-65~250",
            eva: "-",
            vp: "4.5×10E-9",
            thick: "PTFE",
            bo: "PFPE"
        }
        // 나머지 제품들...
    ];

    useEffect(() => {
        const handleResize = () => {
          if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setContainerSize({ width, height });
          }
        };
    
        handleResize(); // 초기화 시 사이즈 설정
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div id="main-wrapper">
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={product1} alt="smartfns 진공, 클린룸 그리이스"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Product</h1>
                                <p className='company-custom-font3 m-t-15'>진공·클린룸 그리이스</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Pchoice1 />
            <div className='m-t-50'></div>
                <Container className='company-custom-font'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><FaGears className='company-text-color2 m-b-10' />&nbsp;진공·클린룸 그리이스</h1>
                        <div className='company-boder-top1 m-t-30 m-b-5'></div>
                        <div className='company-boder-top2'></div>
                        <Row className='background-layer1' style={{ margin: '0 auto', marginTop: '15px', marginBottom: '15px', overflowX: 'auto', fontSize: '1.2rem'}}>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Product</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>외관<br />Color</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>주도<br />NLGI</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>사용온도범위(℃)</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Evaporation(%) <br />149℃, 30hrs</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Vapor Pressure,<br />120℃(torr)</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Thickener</th>
                                            <th style={{ textAlign: 'center', width: '40%' }}>Base Oil</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => (
                                            <tr key={index} style={{ lineHeight :'2rem' }}>
                                                <td style={{ textAlign: 'center' }}>{product.productCode}</td>
                                                <td style={{ textAlign: 'center' }}>{product.color}</td>
                                                <td style={{ textAlign: 'center' }}>{product.nlgi}</td>
                                                <td style={{ textAlign: 'center' }}>{product.temperature}</td>
                                                <td style={{ textAlign: 'center' }}>{product.eva}</td>
                                                <td style={{ textAlign: 'center' }}>{product.vp}</td>
                                                <td style={{ textAlign: 'center' }}>{product.thick}</td>
                                                <td style={{ textAlign: 'center' }}>{product.bo}</td>
                                                {/* {index === 0 && (
                                                    <td rowSpan={products.length} style={{ verticalAlign: 'middle', paddingLeft: '2%' }}>
                                                        <div>
                                                            {product.characteristic.map((item, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    {item}
                                                                    {idx !== product.characteristic.length - 1 && <br />}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>
                                                )} */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div>
                </Container>
                <div className='spacer'></div>
                <Footer/>
            </div>
        </div>
    )
}

export default Product4;