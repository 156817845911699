import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Button, Table, Row, Col, Alert,  Form, FormGroup, Label, Input, Card, CardBody, CardText } from 'reactstrap';
import axios from 'axios';
// import { BiReplyAll } from "react-icons/bi";
import { CgMailReply } from "react-icons/cg";

const QnaReply = ({ isLoggedIn }) => { 
  const navigate = useNavigate();
  const { idx } = useParams();
  const [post, setPost] = useState(null);
  const [content, setContent] = useState('');
  const [content1, setContent1] = useState('');
  const [showAlert, setShowAlert] = useState(false); // showAlert 추가
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  // selectedPost 상태 변수 정의
  const [selectedPost, setSelectedPost] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [editMode, setEditMode] = useState(false);

  // handleCheckboxChange 함수 정의
  const handleCheckboxChange = (e) => {
    setSelectedPost(e.target.checked);
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.post(`/qnaReply/${idx}`);
        
        // 가져온 데이터를 배열로 변환하여 설정
        const dataArray = Object.values(response.data);
        setPost(dataArray);

        console.log("Fetched post data:", response.data);

      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };
  
    fetchPost();
    checkSession();
  }, [idx, isLoggedIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
    const formData = new FormData();

      if (editMode) {
        formData.append('userid', userid);
        formData.append('content', content1);
        const response = await axios.post(`/updateReply/${idx}`, formData, {
          headers: {
            'Content-Type': 'application/json', // 또는 'application/x-www-form-urlencoded'
          }
        });

      } else {
        formData.append('userid', userid);
        formData.append('content', content);
        const response = await axios.post(`/insertReply/${idx}`, formData, {
          headers: {
            'Content-Type': 'application/json', // 또는 'application/x-www-form-urlencoded'
          }
        });

      }
      window.location.reload(); // 등록 후 페이지 새로고침
    }
    catch (error) {
      console.error('Error submitting reply:', error);
      window.alert('답글 등록 중 오류가 발생하였습니다.');
    }
  };

  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setContainerSize({ width, height });
      }
    };

    handleResize(); // 초기화 시 사이즈 설정

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const handleEditClick = () => {
    setEditMode(true);
    setContent1(post[0].content_reply);
  };

  const handleDeleteClick = async () => {
    try {

      await axios.delete(`/qnaReplyDelete/${idx}`);

      window.location.reload(); // 등록 후 페이지 새로고침

    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  if (!post) {
    return <p>Loading...</p>;
  }

  return (
    <> 
        <Row className="justify-content-center m-t-106">
          <Col>
          {post[0].content_reply !== null ? (
            <div>
              <h2 className="m-b-10 text-black font-bold company-custom-font3" style={{ textAlign: 'left' }}>
                <CgMailReply className='fa-2x fa-rotate-180 company-custom-font3' />&nbsp;상담문의 답글
              </h2>
              {editMode ? (
                <Form className='m-t-30' onSubmit={handleSubmit}>
                  <Col md={3} className='p-l-0'>
                    <FormGroup>
                      <Label for="userid">작성자</Label>
                      <Input type="text" name="userid" id="userid" value={userid} readOnly />
                    </FormGroup>
                  </Col>
                  <FormGroup>
                    <Label for="content1">내용</Label>
                    <Input style={{ minHeight: '300px' }} type="textarea" name="content1" id="content1" value={content1} onChange={(e) => setContent1(e.target.value)} />
                  </FormGroup>
                  <div style={{ textAlign : 'right '}}>
                    <Button outline color="info" className='font-bold btn-sm' type="submit">등록</Button>{' '}
                  </div>
                </Form>
              ) : (
                <>
                  <Table>
                    <thead className='company-custom-font3'>
                      <tr>
                        <td style={{ width: '5%', textAlign: 'center' }}></td>
                        <td style={{ width: '10%', textAlign: 'center' }}>작성자</td>
                        <td style={{ width: '60%', textAlign: 'left' }}>내용</td>
                        <td style={{ width: '25%', textAlign: 'left' }}>등록일</td>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center', fontSize: '18px' }} className='company-custom-font5'>
                      {post.map((row, index) => (
                        <tr key={index}>
                          <td style={{ width: '5%', textAlign: 'center' }}>
                            <input type="checkbox" checked={selectedPost} onChange={handleCheckboxChange} />
                          </td>
                          <td style={{ width: '10%', textAlign: 'center' }}>{row.writer_reply}</td>
                          <td style={{ width: '60%', textAlign: 'left' }}>
                            {row && row.content_reply ? (
                              <span style={{ minHeight: '300px', textAlign: 'left' }} className='text-black'>
                                {row.content_reply.split(/\r?\n/).map((line, index) => (
                                  <React.Fragment key={index}>
                                      {line}
                                      <br />
                                  </React.Fragment>
                                ))}
                                {/* {row.content_reply.split(/\r?\n\n/).map((line, index) => (
                                  <React.Fragment key={index}>
                                      {line.split(/\r?\n/).map((innerLine, innerIndex) => (
                                          <React.Fragment key={innerIndex}>
                                              {innerLine}
                                              <br />
                                          </React.Fragment>
                                      ))}
                                  </React.Fragment>
                                ))} */}
                              </span>
                            ) : (
                              <Card>
                                <CardBody>
                                  <CardText>No content available</CardText>
                                </CardBody>
                              </Card>
                            )}
                          </td>
                          <td style={{ width: '25%', textAlign: 'left' }}>{row.regdate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {userid !== null && userid !== '' && (
                  <div style={{ textAlign: 'right' }}>
                    <Button color="warning" className='font-bold btn-sm' onClick={handleEditClick} disabled={!selectedPost}>수정</Button>{' '}
                    <Button color="danger" className='font-bold btn-sm text-white' onClick={handleDeleteClick} disabled={!selectedPost}>삭제</Button>
                  </div>
                  )}
                </>
              )}
            </div>
          ) : (
            userid !== null && userid !== '' && (
              <Form className='m-t-30'>
                <Col md={3} className='p-l-0'>
                  {/* <Alert color="success" isOpen={showAlert} toggle={() => setShowAlert(false)}>{alertMessage}</Alert> */}
                  <FormGroup>
                    <Label for="userid">작성자</Label>
                    <Input type="text" name="userid" id="userid" value={userid} readOnly />
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Label for="content">내용</Label>
                  <Input style={{ minHeight : '300px' }} type="textarea" name="content" id="content" value={content} onChange={(e) => setContent(e.target.value)} />
                </FormGroup>
                <div style={{ textAlign : 'right '}}>
                  <Button outline color="info" className='font-bold btn-sm' onClick={handleSubmit} >등록</Button>{' '}
                </div>
              </Form>
            )
          )};
          </Col>
        </Row>
    </>
  );
};

export default QnaReply;
