
import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import product1 from '../../assets/images/banner/banner2.jpg'
import { FaGears } from "react-icons/fa6";
// import Pchoice2 from './Pchoice2';

const Product6 = () => {
    const products = [
        {
            productCode: "PFC",
            mf: "C₆F₁₂",
            bp: "47",
            density: "1.60",
            fp: "None",
            gd: "20 / 0"
            // characteristic: ["내화학설비", "반도체 Process 장치", "우주선", "인공위성", "고진공설비", "핸드폰 힌지", "골게이터", "프린터/복사기의 Heating rolloer bearing"
            //                 , "도장라인의 트롤리 bearing", "섬유텐더 bearing"
            //                 ]
        },
        {
            productCode: "PFC",
            mf: "C₉F₁₆",
            bp: "75",
            density: "1.76",
            fp: "None",
            gd: "120 / 0"
        },
        {
            productCode: "PFC",
            mf: "C₉F₁₈(CF₃=CF₂)₃",
            bp: "110",
            density: "1.81",
            fp: "None",
            gd: "120 / 0"
        },
        {
            productCode: "HFE",
            mf: "C₁₁H₂F₂₀O",
            bp: "160",
            density: "1.81",
            fp: "None",
            gd: "180 / 0"
        },
        {
            productCode: "HFE",
            mf: " ",
            bp: "59",
            density: "1.51",
            fp: "None",
            gd: "320 / 0"
        },
        {
            productCode: "HFE",
            mf: " ",
            bp: "76",
            density: "1.43",
            fp: "None",
            gd: "200 / 0"
        },
        {
            productCode: "HFE",
            mf: " ",
            bp: "100",
            density: "1.66",
            fp: "None",
            gd: "200 / 0"
        }
        // 나머지 제품들...
    ];

    return (
        <div id="main-wrapper">
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={product1} alt="smartfns 불소 Solvent"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Product</h1>
                                <p className='company-custom-font3 m-t-15'>불소용제</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='spacer'></div>
                <Container className='company-custom-font'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><FaGears className='company-text-color2 m-b-10' />&nbsp;불소용제</h1>
                        <div className='company-boder-top1 m-t-30 m-b-5'></div>
                        <div className='company-boder-top2'></div>
                        <Row className='background-layer1' style={{ margin: '0 auto', marginTop: '15px', marginBottom: '15px', overflowX: 'auto', fontSize: '1.2rem'}}>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: '15%' }}>Product</th>
                                            <th style={{ textAlign: 'center', width: '30%' }}>Molecular Formula</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>Boiling Point(℃)</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Density<br />(g/㎖)</th>
                                            <th style={{ textAlign: 'center', width: '15%' }}>Flash Point(℃)</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>GWP/DOP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => (
                                            <tr key={index} style={{ lineHeight :'2rem' }}>
                                                <td style={{ textAlign: 'center' }}>{product.productCode}</td>
                                                <td style={{ textAlign: 'center' }}>{product.mf}</td>
                                                <td style={{ textAlign: 'center' }}>{product.bp}</td>
                                                <td style={{ textAlign: 'center' }}>{product.density}</td>
                                                <td style={{ textAlign: 'center' }}>{product.fp}</td>
                                                <td style={{ textAlign: 'center' }}>{product.gd}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div>
                </Container>
                <div className='spacer'></div>
                <div className='spacer'></div>
                <Footer/>
            </div>
        </div>
    )
}

export default Product6;