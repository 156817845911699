import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserHistory } from "history";
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import App from "./App.jsx";
import Company1 from "./pages/company/Company1.jsx";
import Company2 from "./pages/company/Company2.jsx";
import Company3 from "./pages/company/Company3.jsx";
import Company4 from "./pages/company/Company4.jsx";
import ScrollToTop from "./pages/Scroll.jsx";
import NoticeMain from "./pages/notice/NoticeMain.jsx";
import NoticeRead from "./pages/notice/NoticeRead.jsx";
import NoticePost from "./pages/notice/NoticePost.jsx";
import NoticeUpdate from "./pages/notice/NoticeUpdate.jsx";
import DataMain from "./pages/data/DataMain.jsx";
import DataRead from "./pages/data/DataRead.jsx";
import DataPost from "./pages/data/DataPost.jsx";
import DataUpdate from "./pages/data/DataUpdate.jsx";
import QnaMain from "./pages/qna/QnaMain.jsx";
import QnaPost from "./pages/qna/QnaPost.jsx";
import QnaRead from "./pages/qna/QnaRead.jsx";
import QnaUpdate from "./pages/qna/QnaUpdate.jsx";
import QnaReply from "./pages/qna/QnaReply.jsx";
import Product1 from "./pages/product/Product1.jsx";
import Product2 from "./pages/product/Product2.jsx";
import Product3 from "./pages/product/Product3.jsx";
import Product4 from "./pages/product/Product4.jsx";
import Product5 from "./pages/product/Product5.jsx";
import Product6 from "./pages/product/Product6.jsx";
import Product7 from "./pages/product/Product7.jsx";
import Product8 from "./pages/product/Product8.jsx";
import Machine1 from "./pages/machine/Machine1.jsx";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    {/* <ScrollToTop /> */}
    <Routes>
      <Route path="/" element={<App />} />
      {/* <Route path="/custom-components" element={<CustomComponents />} />
      <Route path="/components" element={<Components />} /> */}
      <Route path="/company1" element={<Company1 />} />
      <Route path="/company2" element={<Company2 />} />
      <Route path="/company3" element={<Company3 />} />
      <Route path="/company4" element={<Company4 />} />
      <Route path="/product1" element={<Product1 />} />
      <Route path="/product2" element={<Product2 />} />
      <Route path="/product3" element={<Product3 />} />
      <Route path="/product4" element={<Product4 />} />
      <Route path="/product5" element={<Product5 />} />
      <Route path="/product6" element={<Product6 />} />
      <Route path="/product7" element={<Product7 />} />
      <Route path="/product8" element={<Product8 />} />
      <Route path="/machine1" element={<Machine1 />} />
      <Route path="/support1" element={<NoticeMain />} />
      <Route path="/noticeReadView/:idx" element={<NoticeRead />} />
      <Route path="/noticePost" element={<NoticePost />} />
      <Route path="/noticeUpdateView/:idx" element={<NoticeUpdate />} />
      <Route path="/support2" element={<QnaMain />} />
      <Route path="/qnaReadView/:idx" element={<QnaRead />} />
      <Route path="/qnaPost" element={<QnaPost />} />
      <Route path="/qnaUpdateView/:idx" element={<QnaUpdate />} />
      <Route path="/updateReply/:idx" element={<QnaReply />} />
      <Route path="/support3" element={<DataMain />} />
      <Route path="/dataReadView/:idx" element={<DataRead />} />
      <Route path="/dataPost" element={<DataPost />} />
      <Route path="/dataUpdateView/:idx" element={<DataUpdate />} />
      {/* <Route path="/support2" element={<Company2 />} />
      <Route path="/support3" element={<Company3 />} /> */}
    </Routes>
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
