
import React from 'react';
import { Container, Row, Col, Badge } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import Banner  from '../Banner';
import MapNaverDefault from './Map';
import building4 from '../../assets/images/banner/building4.jpg'
import ScrollToTop from '../Scroll';

const Company4 = () => {
    return (
        <div id="main-wrapper">
            <ScrollToTop />
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={building4} alt="스마트에프앤에스"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3 main-font'>About SMART F&S</h1>
                                <p className='company-custom-font3 m-t-15'>Introduction</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='spacer'></div>
                <Container className=''>
                <Row> 
                    <Col lg="6">
                    <div id='map'>
                        <MapNaverDefault />
                    </div>
                    </Col>
                    <Col lg="6">
                        <div className='m-t-150'>
                            {/* <div className='spacer' /> */}
                            <h2 className='company-text-color custom-text-align company-custom-font2 header-font'><i className="fa fa-arrow-right company-text-color2"/>&nbsp;상세주소</h2>
                        <p className='text-black m-t-30'>
                        {/* <span className='d-block font-20 font-medium m-b-20' style={{ textDecoration : 'underline'}}>SMART F&S</span> */}
                            <span className='d-block font-20 font-medium'>경기도 고양시 일산동구 장대길 75-47</span>
                            <span className='d-block font-20 font-medium'>75-47, Jangdae-gil, Ilsandong-gu, Goyang-si, Gyeonggi-do, Republic of Korea</span>
                            <span className='d-block font-20 font-medium m-t-20'>TEL. 070-8158-6414</span>
                            <span className='d-block font-20 font-medium'>FAX. 82-31-901-8238</span>
                        </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='spacer'></div>
            <Footer/>
        </div>
        </div>
    )
}


export default Company4;