import { useEffect, useRef } from 'react';

const MapNaverDefault = () => {
  const mapElement = useRef(null);
  const { naver } = window;

  useEffect(() => {
    if (!mapElement.current || !naver) return;

    const location = new naver.maps.LatLng(37.6374272, 126.7653514);
    const mapOptions = {
      center: location,
      zoom: 19,
      zoomControl: true,
    };

    const map = new naver.maps.Map(mapElement.current, mapOptions);

    const marker = new naver.maps.Marker({
      position: location,
      map,
    });


    const contentString = `
    <div class="iw_inner">
    <h5 style="padding: 10px; font-weight: 600; color:black;">SMART F&S</h5>
    <p style="padding-right: 10px; padding-left: 10px; font-weight: 400; color:black;"><a href="https://naver.me/55y2OFWq" target='_blank' style="color:black; text-decoration: underline;">경기도 고양시 일산동구 장대길 75-47<a/>
    </p>
    </div>
    `;

    const infoWindow = new naver.maps.InfoWindow({
      content: contentString,
      maxWidth: 300,
      backgroundColor: "#eee",
      borderColor: "#2db400",
      borderWidth: 5,
      anchorSize: new naver.maps.Size(30, 30),
      anchorSkew: true,
      anchorColor: "#eee",
      pixelOffset: new naver.maps.Point(20, -20)
    });

    // 마커를 클릭했을 때 InfoWindow를 연다.
    naver.maps.Event.addListener(marker, 'click', () => {
      // infoWindow.open(map, marker);
      if (infoWindow.getMap()) {
        infoWindow.close();
      } else {
        infoWindow.open(map, marker);
      }
    });
  }, [naver]);

  return (
    <>
      <h1 className='title company-text-color company1 company-custom-font2 header-font'><i className='fa fa-map-marker company-text-color2' />&nbsp;오시는 길</h1>
      <div className='map m-t-30' ref={mapElement} style={{ minHeight: '500px' }} />
    </>
  );
};

export default MapNaverDefault;