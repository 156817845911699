
import React from 'react'
import { NavLink } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,  Navbar } from 'reactstrap';

const ProductList = () => {
    return (
        <div>
            <Navbar className="m-3">{' '}
                <UncontrolledDropdown setActiveFromChild>
                    <DropdownToggle tag="nav" className="nav-tabs" caret style={{ fontSize : '17px'}}>
                        그리스 <i className="fa fa-angle-right m-1-5"></i>
                    </DropdownToggle>
                    <DropdownMenu className='custom-tabs'>
                        <DropdownItem><NavLink to="/product1" className='nav-link'>불소그리스</NavLink></DropdownItem>
                        <DropdownItem><NavLink to="/product2" className='nav-link'>건성피막윤활제</NavLink></DropdownItem>
                        <DropdownItem><NavLink to="/product5" className='nav-link'>식품용 그리스</NavLink></DropdownItem>
                        <DropdownItem><NavLink to="/product3" className='nav-link'>반도체용 그리스</NavLink></DropdownItem>
                        <DropdownItem><NavLink to="/product7" className='nav-link'>디스플레이용 그리스</NavLink></DropdownItem>
                        <DropdownItem><NavLink to="/product4" className='nav-link'>진공, 클린룸 그리스</NavLink></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Navbar>         
            <Navbar className="m-3">{' '}
                <UncontrolledDropdown setActiveFromChild>
                    <DropdownToggle tag="nav" className="nav-tabs" caret style={{ fontSize : '17px'}}>
                        불소용제 <i className="fa fa-angle-right m-1-5"></i>
                    </DropdownToggle>
                    <DropdownMenu className='custom-tab1'>
                        <DropdownItem><NavLink to="/product6" className='nav-link'>불소용제</NavLink></DropdownItem>
                        {/* <DropdownItem tag="a"><NavLink className='nav-link'>PFC</NavLink></DropdownItem> */}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Navbar>
            <Navbar className="m-3">{' '}
                <UncontrolledDropdown setActiveFromChild>
                    <DropdownToggle tag="nav" className="nav-tabs" caret style={{ fontSize : '17px'}}>
                        불소코팅제 <i className="fa fa-angle-right m-1-5"></i>
                    </DropdownToggle>
                    <DropdownMenu className='custom-tab'>
                        <DropdownItem tag="a"><NavLink to="/product8" className='nav-link'>불소코팅제</NavLink></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Navbar>
            {/* <DropdownItem divider /> */}
        </div>
    );
}

export default ProductList;
