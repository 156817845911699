
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import OrgChart from './OrgChart';
import { VscOrganization } from "react-icons/vsc";
import building3 from '../../assets/images/banner/building3.jpg'
import ScrollToTop from '../Scroll';

const Company3 = () => {
    return (
        <div id="main-wrapper">
            <ScrollToTop />
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={building3} alt="smartfns"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3 main-font'>About SMART F&S</h1>
                                <p className='company-custom-font3 m-t-15'>Introduction</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='spacer'></div>
            <Container>
                <h1 className='title company-text-color company1 m-b-10 company-custom-font2 header-font'><VscOrganization className='company-text-color2' />&nbsp;조직도</h1>
                <div className='company-boder-top1 m-t-30 m-b-5'></div>
                <div className='company-boder-top2 m-b-30'></div>
            </Container>
            <OrgChart/>
            <Footer/>
            </div>
        </div>
    )
}

export default Company3;