
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import building1 from '../../assets/images/banner/company1.jpg';
import company1 from '../../assets/images/banner/company_product1.jpg';
import { BsFillBuildingsFill } from "react-icons/bs";
import ScrollToTop from '../Scroll';

const Company1 = () => {
    return (
        <div id="main-wrapper">
            <ScrollToTop />
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={building1} alt="smartfns"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3 main-font' >About SMART F&S</h1>
                                <p className='company-custom-font3 m-t-15'>Introduction</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='spacer'></div>
                <Container className='company-custom-font6'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><BsFillBuildingsFill className='company-text-color2' />&nbsp;SMART F&S</h1>
                        <div className='company-boder-top1 m-t-30 m-b-5'></div>
                        <div className='company-boder-top2'></div>
                        <Row className='background-layer1' style={{ margin : '0 auto', marginTop : '15px', marginBottom : '15px' }}> 
                            <Col lg="6" className='' style={{ lineHeight : '2'}}>
                            <p className='text-black m-t-30'>
                                <span className='d-block'>안녕하십니까?</span>
                                <span className='d-block'>저희 SMART F&S 홈페이지를 방문하여 주신 고객 여러분 진심으로 환영합니다.</span>
                            </p>
                            <p className='text-black'>
                                <span>"실용과 최고의 고객만족으로 최선의 제품을 연구-개발하여 </span><span className='text-info font-weight-bold'>친환경 제품</span><span>으로 거듭나겠습니다."</span>
                            </p>
                            <p className='text-black'>
                                <span className='d-block'>SMART F&S(주)는 산업용 특수윤활제를 제조, 공급하고 있는 전문회사로서, </span>
                                <span>현재 우리나라 산업발전에 걸림돌이 된 고가의 수입 제품으로 인한 각 기업의 경제적 손실을 감안하여, 우수한 기술력을 바탕으로 선진 제품에 버금가는 제품들을 끊임없이 연구-개발하여 </span>
                                <span className='text-info font-weight-bold'>『고품질-저렴한』 </span>
                                <span>가격의 제품으로 재탄생시켜 우리나라 경제발전에 기여하고자 항상 노력할 것입니다.</span>
                            </p>
                            <p className='text-black'>
                                <span className='d-block'>SMART F&S(주)는 모든 산업분야에 필요한 설비 및 장비에 소요되는 각종 윤활제의 특수성을 신중히 고려하여 </span>
                                <span className='d-block'>보다 나은 품질의 핵심기술로 미래 신 성장 산업의 원동력으로 약진할 것을 약속드립니다.</span>
                                <span className='d-block'><span className='text-info font-weight-bold'>"고객만족-고객감동"</span>을 위해 여러분을 최우선으로 생각하며, </span>
                                <span className='d-block'>가장 친환경적인 특수윤활제-제조업체의 대명사로 거듭나는 SMART F&S(주)가 되겠습니다. </span>
                            </p>
                            {/* &nbsp; 공백 */}
                            <p className='text-black'>
                                <span className='d-block'>감사합니다.</span>
                            </p>
                            <p className='text-black company2'>
                                <span className='d-block'>SMART F&S(주) 대표이사</span>
                            </p>   
                            </Col>
                            <Col lg="6">
                            <div className="image-container1 m-t-30">
                                <img src={company1} alt="smartfns 불소그리스"/>
                                <div className="background-layer2"></div>
                            </div>
                            </Col>
                        </Row>
                        <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div>
                </Container>
                <div className='spacer'></div>
                <Footer/>
            </div>
        </div>
    )
}

export default Company1;