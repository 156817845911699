
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import product1 from '../../assets/images/banner/banner2.jpg';
import sf1 from '../../assets/images/product/SF-FG4-TDS_1.jpg';
import sf2 from '../../assets/images/product/SF-FG4-TDS_2.jpg';
import sc1 from '../../assets/images/product/SF-C1-TDS_1.jpg';
import sc2 from '../../assets/images/product/SF-C1-TDS_2.jpg';
import sc3 from '../../assets/images/product/SF-C1-TDS_3.jpg';
import sd1 from '../../assets/images/product/SF-DC-TDS_1.jpg';
import sd2 from '../../assets/images/product/SF-DC-TDS_2.jpg';
import sd3 from '../../assets/images/product/SF-DC-TDS_3.jpg';
import { FaGears } from "react-icons/fa6";
import Pchoice1 from './Pchoice1';

const Product3 = () => {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => {
          if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setContainerSize({ width, height });
          }
        };
    
        handleResize(); // 초기화 시 사이즈 설정
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div id="main-wrapper">
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={product1} alt="smartfns 반도체, 디스플레이용"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Product</h1>
                                <p className='company-custom-font3 m-t-15'>반도체 및 디스플레이용</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Pchoice1 />
            <div className='m-t-50'></div>
                <Container className='company-custom-font'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><FaGears className='company-text-color2 m-b-10' />&nbsp;디스플레이용 그리스 [SF-DC]</h1>
                    <div className='company-boder-top1 m-t-30 m-b-5' />
                    <div className='company-boder-top2' />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto', marginTop: '15px',  marginBottom: '15px'}}>
                            <div style={{ marginBottom: '10px'}}>
                                <img src={sd1} alt="smartfns 디스플레이용 그리스" style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <img src={sd2} alt="smartfns 디스플레이용 그리스" style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                            <div>
                                <img src={sd3} alt="smartfns 디스플레이용 그리스" style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                        </div>
                        <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div>
                </Container>
                <div className='spacer'></div>
                <Footer/>
            </div>
        </div>
    )
}

export default Product3;