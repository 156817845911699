import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Button, Table, Row, Col, Alert } from 'reactstrap';
import { GoDownload } from "react-icons/go";
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import support from '../../assets/images/banner/support1.jpg'

const NoticeRead = ({ isLoggedIn }) => { 
  const navigate = useNavigate();
  const { idx } = useParams();
  const [post, setPost] = useState(null);
  const [prevIdx, setPrevIdx] = useState(null);
  const [nextIdx, setNextIdx] = useState(null);
  const [showAlert, setShowAlert] = useState(false); // showAlert 추가
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/noticeRead/${idx}`);

        // 가져온 데이터를 배열로 변환하여 설정
        const dataArray = Object.values(response.data);
        setPost(dataArray);

        console.log(dataArray);
  
        // 이하 코드는 동일
        if (response.data.prevIdx !== null) {
          setPrevIdx(response.data.prevIdx);
        } else {
          setPrevIdx(null);
        }
        if (response.data.nextIdx !== null) {
          setNextIdx(response.data.nextIdx);
        } else {
          setNextIdx(null);
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };
  
    fetchPost();
    checkSession();
  }, [idx, , isLoggedIn]);

  const checkSession = () => {
    const loggedInUser = sessionStorage.getItem('user');
    if (loggedInUser) {
      // 세션에 저장된 사용자 정보가 있을 경우, 해당 정보를 state에 설정
      const user = JSON.parse(loggedInUser);
      setUsername(user.username);
      setUserid(user.userid);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setContainerSize({ width, height });
      }
    };

    handleResize(); // 초기화 시 사이즈 설정

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const handleEditClick = () => {
    navigate(`/noticeUpdateView/${idx}`);
  };

  const handleDeleteClick = async () => {
    try {
      await axios.delete(`/noticeDelete/${idx}`);
      setShowAlert(true); // showAlert를 true로 설정하여 알림창 표시
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false); // showAlert를 false로 설정하여 알림창 숨김
    navigate('/support1'); // 삭제 후 리다이렉트할 경로 설정
  };

  const imageURL = 'http://smartfns1.cafe24app.com/uploads';

  if (!post) {
    return <p>Loading...</p>;
  }
  
  return (
    <div id="main-wrapper">
      <Header isLoggedIn={isLoggedIn} username={username} />
      <div className="page-wrapper m-t-106 company-bg">
        <div className='modal-open'> 
            <Row className='justify-content-center'>
                <Col className='align-items-center b-b-custom'>
                    <div className="image-container">
                        <img src={support} alt="smartfns"/>
                        <div className="background-layer"></div>
                        <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Support</h1>
                                <p className='company-custom-font3 m-t-30'>Notice</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='spacer'></div>
        <Container className='justifiy-content-center'>
            <h1 className='text-black m-t-5 custom-text-align m-l-10 company-custom-font3 title-font'><i className="fa fa fa-check m-b-5 company-text-color2" />&nbsp;{post[0].title}</h1>
          <div className='buttons m-b-10'>
            {userid && ( // userid가 null이 아닌 경우에만 버튼 표시
              <>
                <Button outline color="info" className='btn-sm font-bold m-r-5' onClick={handleEditClick}>수정</Button>{' '}
                <Button outline color="info" className='btn-sm font-bold m-r-5' onClick={handleDeleteClick}>삭제</Button>{' '}
              </>
            )}
            <Button outline color="info" className='btn-sm font-bold'><Link to='/support1' className='nav-link'>목록</Link></Button>{' '}
          </div>
          <Alert color="success" isOpen={showAlert} toggle={handleCloseAlert}>
            게시글이 성공적으로 삭제되었습니다.
          </Alert>
          <Table style={{ minHeight : '800px' }}>
            <thead className='company-custom-font4'>
              <tr>
                <th style={{ width : '30%', }}>작성자 : {post[0].writer}</th>
                <th style={{ width : '50%', textAlign : 'right' }}>등록일 : {post[0].regdate}</th>
                {/* <th style={{ width : '30%', textAlign : 'left'  }}>수정일 : {post[0][5]}</th> */}
              </tr>    
            </thead>
            <tbody className='company-custom-font5' style={{ fontSize : '18px' }}>
            <tr>
              <td colSpan="3" tag='CardBody' style={{ minHeight : '500px' }}>
                <span tag='CardText' className='text-black' style={{ minHeight : '500px' }}>
                {post && post[0].content ? (
                  post[0].content.split(/\r?\n/).map((line, index) => (
                      <React.Fragment key={index}>
                          {line}
                          <br />
                      </React.Fragment>
                  ))
                  // {/* {post && post[0].content ? (
                  //   post[0].content.split(/\r?\n\n/).map((line, index) => (
                  //     <React.Fragment key={index}>
                  //       {line.split(/\r?\n/).map((innerLine, innerIndex) => (
                  //           <React.Fragment key={innerIndex}>
                  //               {innerLine}
                  //               <br />
                  //           </React.Fragment>
                  //       ))}
                  //     </React.Fragment>
                  //   )) */}
                  ) : (
                    <p>No content available</p>
                  )}
                </span>
                <span tag='CardText' className='text-black'>
                <div ref={containerRef} className='m-t-30'>
                {post && Object.values(post)
                .filter(imageInfo => imageInfo && imageInfo.uuid_filename) // null 값인 요소 필터링
                .map((imageInfo, imageIndex) => (
                    <div key={imageIndex}>
                        {/* 이미지 정보 출력 */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* 이미지 URL로 렌더링 */}
                            {imageInfo.filename && (imageInfo.filename.toLowerCase().endsWith('.png') || imageInfo.filename.toLowerCase().endsWith('.jpg') || imageInfo.filename.toLowerCase().endsWith('.jpeg') || imageInfo.filename.toLowerCase().endsWith('.gif')) ? (
                                <img
                                    src={`${imageURL}/${encodeURIComponent(imageInfo.uuid_filename)}`}
                                    alt={`이미지 ${imageIndex + 1}`}
                                    style={{
                                        maxWidth: '100%', // 최대 너비를 100%로 설정하여 container에 맞게 조절
                                        maxHeight: '100%', // 최대 높이를 100%로 설정하여 container에 맞게 조절
                                        width: 'auto',
                                        height: 'auto'
                                    }}
                                />
                            ) : (
                                <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid gray', padding: '10px' }} className='m-t-5'>
                                    <span style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <a className='text-black' href={`/uploads/${imageInfo.uuid_filename}`} download={`${decodeURIComponent(imageInfo.filename)}`} style={{ textDecoration: 'none', fontSize: '14px'}}>{imageInfo.filename} &nbsp;<GoDownload /></a>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                </div>
                </span>
                </td>
              </tr>
            </tbody>
            {post[0].updated !== '' && (
            <tfoot>
              <td></td>
              <td style={{ textAlign : 'right', margin: '0 auto'  }} className='company-custom-font5'>수정일 : {post[0].updated}</td>
                {/* <th style={{ textAlign : 'right', margin: '0 auto'  }}>수정일 : {post[0][5]}</th>
              </tr> */}
            </tfoot>
            )}
          </Table>
          <Row className="m-t-4">
          <Col className="text-center text-black">
          <div className='d-flex justify-content-between'>
            <div>
              {prevIdx !== null && (
                <Button outline color="inverse" className="btn font-bold" tag={Link} to={`/noticeReadView/${prevIdx}`}>
                  이전 게시글
                </Button>
              )}
            </div>
            <div>
              {nextIdx !== null && (
                <Button outline color="inverse" className="btn font-bold" tag={Link} to={`/noticeReadView/${nextIdx}`} >
                  다음 게시글
                </Button>
              )}
            </div>
          </div>
          </Col>
          </Row>
        </Container>
        {/* 삭제 성공시 알림창 */}
        <div className='spacer'></div>
      </div>
      <Footer />
    </div>
  );
};

export default NoticeRead;
