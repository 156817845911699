
import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import product1 from '../../assets/images/banner/banner2.jpg'
import { FaGears } from "react-icons/fa6";
import Pchoice1 from './Pchoice1';

const Product2 = () => {
    const products = [
        {
            productCode: "FCL-235H",
            color: "White",
            fp: "None",
            temperature: "-60~120",
            characteristic: ["Switch", "Cup hold", "Air Vent", "Hinge", "Connect", "Print / Copy machine", 
                            "Slim part / DVD-ROM / CD-ROM / Mechanism Camera / Camcord", "Moter shaft"
                            , "Lubrication of Rubber", "Pelt"
                            ]
        },
        {
            productCode: "FCL-355H",
            color: "White",
            fp: "None",
            temperature: "-50~200"
        },
        {
            productCode: "FCL-507H",
            color: "White",
            fp: "None",
            temperature: "-50~200"
        },
        {
            productCode: "FCL-709H",
            color: "White",
            fp: "None",
            temperature: "-50~200"
        },
        {
            productCode: "FCL-1000H",
            color: "White",
            fp: "None",
            temperature: "-50~200"
        },
        {
            productCode: "FCL-1000S",
            color: "White",
            fp: "None",
            temperature: "-40~250"
        },
        {
            productCode: "FCL-1000TS",
            color: "Clear",
            fp: "None",
            temperature: "-40~250"
        },
        {
            productCode: "FCL-822L",
            color: "Clear",
            fp: "None",
            temperature: "-50~200"
        },
        {
            productCode: "FCL-822",
            color: "Clear",
            fp: "None",
            temperature: "-50~200"
        },
        {
            productCode: "FCL-704",
            color: "Clear",
            fp: "None",
            temperature: "-50~180"
        }
        // 나머지 제품들...
    ];

    return (
        <div id="main-wrapper">
            <Header/>
            <div className="page-wrapper m-t-106 company-bg">
            <div className='modal-open'> 
                <Row className='justify-content-center'>
                    <Col className='align-items-center b-b-custom'>
                        <div className="image-container">
                            <img src={product1} alt="스마트에프앤에스 건성피막윤활제"/>
                            <div className="background-layer"></div>
                            <div className="image-text m-t-20" style={{ verticalAlign : 'middle' }}>
                                <h1 className='text-white company-custom-font3' style={{ fontSize : '4rem' }}>Product</h1>
                                <p className='company-custom-font3 m-t-15'>건성피막윤활제</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Pchoice1 />
            <div className='m-t-50'></div>
                <Container className='company-custom-font'>
                    <h1 className='title company-text-color company1 company-custom-font2 header-font'><FaGears className='company-text-color2 m-b-10' />&nbsp;건성피막윤활제</h1>
                        <div className='company-boder-top1 m-t-30 m-b-5'></div>
                        <div className='company-boder-top2'></div>
                        <Row className='background-layer1' style={{ margin: '0 auto', marginTop: '15px', marginBottom: '15px', overflowX: 'auto', fontSize: '1.2rem'}}>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Product</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>외관<br />Color</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>인화점<br />F.P</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>사용온도범위(℃)</th>
                                            <th style={{ textAlign: 'center', width: '40%' }}>특성 / 적용부위<br />(Characteristic / Application)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => (
                                            <tr key={index} style={{ lineHeight :'2rem' }}>
                                                <td style={{ textAlign: 'center' }}>{product.productCode}</td>
                                                <td style={{ textAlign: 'center' }}>{product.color}</td>
                                                <td style={{ textAlign: 'center' }}>{product.fp}</td>
                                                <td style={{ textAlign: 'center', borderRight : '1px solid #ddd' }}>{product.temperature}</td>
                                                {index === 0 && (
                                                    <td rowSpan={products.length} style={{ verticalAlign: 'middle', paddingLeft: '2%' }}>
                                                        <div>
                                                            {product.characteristic.map((item, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    {item}
                                                                    {idx !== product.characteristic.length - 1 && <br />}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <div className='company-boder-top2 m-b-5'></div>
                        <div className='company-boder-top1'></div>
                </Container>
                <div className='spacer'></div>
                <Footer/>
            </div>
        </div>
    )
}

export default Product2;