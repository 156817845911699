
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header  from '../Header';
import Footer  from '../Footer';
import product1 from '../../assets/images/banner/banner2.jpg'
import { FaCircleDot } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';

const Pchoice1 = () => {
    return (
        <Container className="m-t-50">
        <Row>
        <Col lg='12'>
        <div className='pchoice'>
            <div className="table-container company-text-color company-custom-font5">
            <div className="row">
                <div className="col">
                <span><NavLink to='/product1' className='nav-link'><FaCircleDot />&nbsp;불소그리스</NavLink></span>
                </div>
                <div className="col">
                <span><NavLink to='/product2' className='nav-link'><FaCircleDot />&nbsp;건성피막윤활제</NavLink></span>
                </div>
                <div className="col">
                <span><NavLink to='/product5' className='nav-link'><FaCircleDot />&nbsp;식품용 그리스</NavLink></span>
                </div>
            </div>
            <div className="row m-t-10">
                <div className="col">
                <span><NavLink to='/product3' className='nav-link'><FaCircleDot />&nbsp;반도체용 그리스</NavLink></span>
                </div>
                <div className="col">
                <span><NavLink to='/product7' className='nav-link'><FaCircleDot />&nbsp;디스플레이용 그리스</NavLink></span>
                </div>
                <div className="col">
                <span><NavLink to='/product4' className='nav-link'><FaCircleDot />&nbsp;진공, 클린룸 그리이스</NavLink></span>
                </div>
                {/* <span></span> */}
                </div>
            </div>
            <div className="row m-t-10">
                <div className="col">
                    {/* <span></span> */}
                </div>
                <div className="col">
                    {/* <span></span> */}
                </div>
                <div className="col">
                    {/* <span></span> */}
                </div>
            </div>
        </div>
        </Col>
        </Row>
        </Container>
    )
}

export default Pchoice1;